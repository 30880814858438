import React, {useCallback, useMemo} from 'react'
import {Box, Dialog, DialogContent, Divider, Link} from "@mui/material";
import {StudentDTO} from "../../generated/api";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DialogActions from "@mui/material/DialogActions";

interface InstructionForStudentProps {
    user: StudentDTO | null
    open: boolean
    onClose: () => void
    title?: string
    subtitle?: string
}

export default function InstructionForStudent(props: InstructionForStudentProps) {
    const { open, user, onClose, title, subtitle } = props

    const onCopy = useCallback(() => {
        const textToCopy = `
            ${user?.name?.split(' ')[0]}, добрый день!
            
            Мы будем с тобой заниматься на платформе Учеба.ру, вот ссылка для входа в твой личный кабинет:
            ${user?.inviteLink || 'https://student.ucheba.ru/'}
            
            В личном кабинете ты сможешь видеть расписание наших с тобой занятий, выполнять домашние работы и видеть результаты проверки с моими комментариями.
            
            Сразу после входа в личный кабинет, пожалуйста, подключи Телеграм-бота (в личном кабинете увидишь инструкцию), чтобы получать от меня уведомления о занятиях и домашних работах.
            
            До встречи на занятиях!
        `

        navigator.clipboard.writeText(textToCopy).catch((err) => {console.error('Ошибка копирования: ', err)
        })
    },[user])

    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <Box sx={{p: 3}}>
                    <Typography variant='h5' fontWeight='500' sx={{mb: 1}}>
                        {title || 'Пригласите ученика, отправив ссылку для входа в его личный кабинет'}
                    </Typography>

                    <Typography fontSize={'18px'}>
                        {subtitle || ''}
                    </Typography>
                </Box>

                <Divider />

                <Box sx={{p:3,backgroundColor:'#F5F7F9'}}>
                    <Typography fontSize='18px'
                                lineHeight='24px'
                                fontWeight={500}
                                sx={{mb:1}}
                    >
                        {user?.name?.split(' ')[0]}, добрый день!
                    </Typography>

                    <Box sx={{fontSize:'14px', lineHeight:'20px', fontWeight:400, mb: 1}} style={{
                        wordBreak: 'break-all'
                    }}>
                        Мы будем с тобой заниматься на платформе Учеба.ру, вот ссылка для входа в твой личный кабинет:{' '}
                        <Link target='_blank' href={user?.inviteLink || 'https://student.ucheba.ru/'}>{user?.inviteLink || 'https://student.ucheba.ru/'}</Link>
                    </Box>

                    <Box sx={{fontSize:'14px', lineHeight:'20px', fontWeight:400, mb: 1}}>
                        В личном кабинете ты сможешь видеть расписание наших с тобой занятий, выполнять домашние работы и
                        видеть результаты проверки с моими комментариями.
                    </Box>

                    <Box sx={{fontSize:'14px', lineHeight:'20px', fontWeight:400, mb: 1}}>
                        Сразу после входа в личный кабинет, пожалуйста, подключи Телеграм-бота (в личном кабинете увидишь инструкцию),{' '}
                        чтобы получать от меня уведомления о занятиях и домашних работах.
                    </Box>

                    <Box sx={{fontSize:'14px', lineHeight:'20px', fontWeight:400, mb: 2}}>
                        До встречи на занятиях!
                    </Box>

                    <Button onClick={onCopy}
                            variant='outlined'
                            fullWidth
                            startIcon={<ContentCopyRoundedIcon/>}>
                        Скопировать текст
                    </Button>
                </Box>
            </DialogContent>
            <Divider/>

            <DialogActions sx={{p: 3}}>
                <Button variant='contained' onClick={onClose} sx={{p:'12px 41px'}}>готово</Button>
            </DialogActions>
        </Dialog>
    )
}