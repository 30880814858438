import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import localization from './localization'
import 'mathlive/fonts.css'
import { MathfieldElement, convertLatexToMathMl, convertLatexToMarkup } from 'mathlive'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '../Dialog/Dialog'
import Typography from '@mui/material/Typography'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'math-field': React.DetailedHTMLProps<
        React.HTMLAttributes<MathfieldElement>,
        MathfieldElement
      >
    }
  }
}
document.body.style.setProperty('--keyboard-zindex', '3000')
MathfieldElement.strings = { ...MathfieldElement.strings, ru: localization }

interface MathEditorDialogProps {
  initialValue?: string
  open: boolean
  onClose: () => void
  onInsert: (formula: MathFormulaResult) => void
}

export interface MathFormulaResult {
  latex: string
  mathMl: string
  markup: string
}

export default function MathEditorDialog(props: MathEditorDialogProps) {
  const { open, initialValue, onClose, onInsert } = props

  const [value, setValue] = React.useState<string>(initialValue || '')
  const field = useRef<MathfieldElement>()

  const onInsertClick = useCallback(() => {
    onInsert({
      latex: value,
      mathMl: convertLatexToMathMl(value),
      markup: convertLatexToMarkup(value),
    })
  }, [onInsert, value])

  useEffect(() => {}, [])

  return (
    <Dialog fullWidth open={open} maxWidth='md'>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
          zIndex: 3,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack sx={{ p: { xs: 2, sm: 3 } }} spacing={2}>
          <Typography sx={{ fontSize: { xs: '24px', sm: '32px' } }}>
            Редактор формул
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
                alignItems: { xs: 'unset', sm: 'center' },
              },
            }}
          >
            <math-field
              //@ts-ignore
              ref={field}
              //@ts-ignore
              onInput={(evt) => setValue(evt.target.value)}
              style={{
                width: '100%',
                fontSize: '1.5em',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              {value}
            </math-field>
            <Button
              sx={{ m: { xs: '16px 0 0', sm: '0 0 0 16px' } }}
              disabled={!value}
              onClick={onInsertClick}
              variant='contained'
            >
              {initialValue ? 'Сохранить' : 'Вставить'}
            </Button>
          </Box>
          <TextField
            label='LaTeX'
            multiline
            fullWidth
            rows={3}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
