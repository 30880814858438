import React, { memo } from 'react'
import UserAvatar from '../../../custom/UserAvatar'
import TextField from '@mui/material/TextField'

interface CheckerAutocompleteInputProps {
  params?: any
  options?: Record<string, any>
  label?: string
  value?: any
  error?: React.ReactNode
  marginLeft?: string
}

const CheckerAutocompleteInput: React.FC<CheckerAutocompleteInputProps> = (props) => {
  const { params, label, value, error, marginLeft, options } = props

  return (
    <TextField
      {...params}
      fullWidth
      label={label}
      error={!!error}
      helperText={error}
      InputLabelProps={{
        shrink: !!value || !!params.inputProps.value,
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            {typeof params.inputProps.value === 'string' &&
            value &&
            params.inputProps.value ? (
              <UserAvatar name={params.inputProps.value} marginLeft={marginLeft} />
            ) : null}
            {params.InputProps.startAdornment}
          </>
        ),
      }}
      {...options}
    />
  )
}

export default memo(CheckerAutocompleteInput)
