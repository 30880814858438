import React, { FC, useState } from 'react'
import { Avatar, Box, Typography, useMediaQuery } from '@mui/material'
import { stringAvatar } from '../bll/stringAvatar'
import theme from '../../../../styles/globalTheme'
import AboutStudentDialog from '../../../custom/AboutStudentDialog/AboutStudentDialog'

interface ITestCheckingResultTasksListHeaderProps {
  testName?: string
  userName?: string
  userPhone?: string | null
  studentId?: number
}

const TestCheckingResultTasksListHeader: FC<ITestCheckingResultTasksListHeaderProps> = (
  props
) => {
  const { testName, userName, userPhone, studentId } = props
  const [openUserInfo, setOpenUserInfo] = useState(false)
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: { xs: 'unset', sm: 'space-between' },
        flexDirection: { xs: 'column', sm: 'unset' },
        padding: { sm: '16px 0', xs: '16px' },
        position: 'relative',
      }}
    >
      {testName && (
        <Typography
          fontWeight={500}
          component='h1'
          sx={{
            whiteSpace: { xs: 'wrap', sm: 'nowrap' },
            fontSize: { xs: '24px', sm: '32px' },
          }}
        >
          {testName}
        </Typography>
      )}

      {userName && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: { xs: 'unset', sm: 'absolute' },
            top: '16px',
            right: 0,
            overflow: 'hidden',
            mt: { xs: '16px', sm: 0 },
          }}
        >
          {!isMobileScreen && (
            <div
              style={{
                width: '40px',
                height: '40px',
                background:
                  'linear-gradient(0.25turn, rgba(245, 247, 249, 0), rgba(245, 247, 249, 1))',
              }}
            />
          )}

          <div
            onClick={() => setOpenUserInfo(true)}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(245, 247, 249, 1)',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <div style={{ paddingRight: '12px' }}>
              <Avatar
                {...stringAvatar(userName, {
                  width: isMobileScreen ? 24 : 40,
                  height: isMobileScreen ? 24 : 40,
                  fontSize: isMobileScreen ? '10px' : '20px',
                  fontWeight: 600,
                })}
              />
            </div>

            <Typography
              fontWeight={400}
              sx={{
                color: '#1d8afe',
                fontSize: { xs: '16px', sm: '32px' },
                display: { xs: 'inline', sm: 'inherit' },
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {userName}
            </Typography>
          </div>
        </Box>
      )}

      {studentId && openUserInfo && (
        <AboutStudentDialog
          open={openUserInfo}
          onClose={() => setOpenUserInfo(false)}
          studentId={studentId}
        />
      )}
    </Box>
  )
}

export default TestCheckingResultTasksListHeader
