import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Chip, Divider, keyframes, Tooltip, useMediaQuery } from '@mui/material'
import InfoBlock, {
  InfoBlockHeader,
  InfoBlockContent,
  InfoBlockFooter,
} from './InfoBlock'
import {
  ClassTypeEnum,
  EmployeeTaskDTO,
  TaskExamStructTaskDTO,
  TaskStatsDTO,
  TaskStatusEnum,
} from '../../generated/api'
import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { getCountForm } from '../../helpers/number'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import dayjs from 'dayjs'
import 'mathlive/static.css'
import theme from '../../styles/globalTheme'

const pulseAnimation = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`

const conditionStyle = {
  height: '140px',
  overflow: 'hidden',
  position: 'relative',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '32px',
    content: '""',
    position: 'absolute',
    bottom: '0',
    left: '0',
    background: 'linear-gradient(rgba(255,255,255,0.2), rgba(255,255,255,0.8), white)',
  },
}

const chipStyle = {
  fontSize: '14px',
  lineHeight: '16px',
  backgroundColor: '#F5F7F9',
}

interface TaskItemProps {
  item: EmployeeTaskDTO
  taskStat: TaskStatsDTO | null
  actions?: ReactNode[]
  mobileActions?: ReactNode[]
  isVisibleSubject?: boolean
  isVisibleStatus?: boolean
  isTasksLoading?: boolean
}

export default function TaskItem(props: TaskItemProps) {
  const {
    item,
    actions,
    taskStat,
    isVisibleSubject = true,
    isVisibleStatus = true,
    isTasksLoading,
    mobileActions,
  } = props
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const conditionRef = useRef<HTMLDivElement>()
  const conditionRef2 = useRef<HTMLDivElement>()

  const [hideCondition, setHideCondition] = useState(false)
  const [hideCondition2, setHideCondition2] = useState(false)
  const [expandedCondition, setExpandedCondition] = useState(false)

  const topics = useMemo(() => {
    if (item.topics && item.topics.length > 0) {
      return item.topics.map((topic) => topic.name).join(', ')
    }
    return null
  }, [item.topics])

  const variantAnswerText = useMemo(() => {
    switch (item.type) {
      case 'selection':
        return 'Ответ выбором варианта'
      case 'text':
        return 'Ответ вводом'
      default:
        return 'Ответ загрузкой решения'
    }
  }, [item])

  const scoreAnswerText = useMemo(() => {
    if ('availablePartialAnswer' in item && item.score) {
      return item.availablePartialAnswer
        ? `Максимум ${item?.score} ${getCountForm(item.score, ['балл', 'балла', 'баллов'])}, допускается неполный ответ`
        : `${item?.score} ${getCountForm(item.score, ['балл', 'балла', 'баллов'])}`
    }
    return item.score
      ? `${item?.score} ${getCountForm(item.score, ['балл', 'балла', 'баллов'])}`
      : null
  }, [item])

  const expectedTypeAnswerManul = useMemo(() => {
    if ('withAudio' in item && 'withPhoto' in item && 'withText' in item) {
      if (item.withAudio) {
        return 'аудио'
      }
      if (item.withPhoto) {
        return 'файл'
      }
      if (item.withText) {
        return 'текст'
      }
    }
    return null
  }, [item])

  const expectedTypeAnswerText = useMemo(() => {
    if ('expectedValueType' in item) {
      switch (item.expectedValueType) {
        case 'number':
          return 'число'
        case 'sequence_of_numbers':
          return 'последовательность чисел'
        default:
          return 'свободный'
      }
    }
    return null
  }, [item])

  const taskStatus = useMemo(() => {
    if (isVisibleStatus) {
      switch (item.status) {
        case TaskStatusEnum.PUBLISHED:
          return (
            <Tooltip title='Опубликовано'>
              <CheckCircleIcon color='success' />
            </Tooltip>
          )
        case TaskStatusEnum.ARCHIVED:
          return (
            <Tooltip title='Архив'>
              <StopCircleIcon color='warning' />
            </Tooltip>
          )
        default:
          return (
            <Tooltip title='Черновик'>
              <WatchLaterIcon sx={{ color: '#1319208F' }} />
            </Tooltip>
          )
      }
    }
    return null
  }, [item])

  const taskTypeFormat = useMemo(() => {
    switch (item.type) {
      case 'selection':
        return 'Ответ выбором'
      case 'text':
        return 'Ответ вводом'
      default:
        return 'Ответ загрузкой'
    }
  }, [item.type])

  const examTypes = useMemo(() => {
    if (item.examStructTasks && item.examStructTasks.length > 0) {
      return item.examStructTasks.reduce<TaskExamStructTaskDTO[]>((acc, current) => {
        if (current.examType && current.examType.name) {
          const isDuplicate = acc.some(
            (item) => item.examType?.name === current.examType?.name
          )
          if (!isDuplicate) {
            acc.push(current)
          }
        }
        return acc
      }, [])
    }
    return null
  }, [item])

  const getClassLabel = (option: ClassTypeEnum) => {
    switch (option) {
      case ClassTypeEnum.PRESCHOOL:
        return 'Дошкольник'
      case ClassTypeEnum.FINISHED_SCHOOL:
        return 'Закончил'
      default:
        return `${option} класс`
    }
  }

  const chips = useMemo(() => {
    const elements: (ReactElement<typeof Chip> | null)[] = []
    if (item.subject && isVisibleSubject) {
      elements.push(<Chip label={item.subject.name} size='small' sx={chipStyle} />)
    }
    if (item.class) {
      elements.push(
        <Chip label={getClassLabel(item.class)} size='small' sx={chipStyle} />
      )
    }

    if (examTypes) {
      {
        examTypes.forEach((examType, examTypeIndex) =>
          elements.push(
            <Chip
              key={examTypeIndex}
              size='small'
              label={examType?.examType?.name}
              sx={{
                fontSize: '14px',
                lineHeight: '16px',
                backgroundColor: '#F5F7F9',
              }}
            />
          )
        )
      }

      {
        examTypes.forEach((examType, examTypeIndex) =>
          elements.push(
            <Chip
              key={examTypeIndex}
              size='small'
              label={`№${examType?.number}`}
              sx={{
                fontSize: '14px',
                lineHeight: '16px',
                backgroundColor: '#F5F7F9',
              }}
            />
          )
        )
      }
    }

    if (item.type) {
      elements.push(
        <Chip
          size='small'
          label={taskTypeFormat}
          sx={{
            fontSize: '14px',
            lineHeight: '16px',
            backgroundColor: '#F5F7F9',
          }}
        />
      )
    }

    return elements
  }, [item, examTypes, isVisibleSubject])

  const taskEndFooter = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <Typography
          fontWeight={400}
          sx={{
            color: '#13192061',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.17px',
          }}
        >
          ID:{item.id}
        </Typography>
        <Box sx={{ color: '#13192061' }}>・</Box>
        {item.changedAt && (
          <Typography
            fontWeight={400}
            sx={{
              color: '#13192061',
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '0.17px',
            }}
          >
            {`задание изменено ${dayjs(item?.changedAt).format('DD.MM.YY')}`}
          </Typography>
        )}
      </Box>
    )
  }, [item])

  const taskStartStatFooter = useMemo(() => {
    if (taskStat) {
      return (
        <Typography
          fontWeight={400}
          sx={{
            textDecoration: 'none',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.17px',
          }}
        >
          {taskStat.attemptsCount &&
            `${taskStat?.attemptsCount} ${getCountForm(taskStat.attemptsCount, ['выполнил', 'выполнили', 'выполнили'])}, `}
          {taskStat.successPercent}% верно
        </Typography>
      )
    }
    return null
  }, [taskStat])

  const isOpenConditions = useRef(false)

  useEffect(() => {
    if (isOpenConditions.current) return

    if (conditionRef2.current) {
      const { clientHeight } = conditionRef2.current
      if (clientHeight > 140) {
        setHideCondition2(true)
        isOpenConditions.current = true
      }
    }

    if (conditionRef.current) {
      const { clientHeight } = conditionRef.current
      if (clientHeight > 140) {
        setHideCondition(true)
        isOpenConditions.current = true
      }
    }
  }) // не добавлять зависимости, т.к в какой-то момент ref может быть undefined, да же если пришёл контент

  return (
    <InfoBlock
      sx={{
        pointerEvents: isTasksLoading ? 'none' : 'auto',
        opacity: isTasksLoading ? '0.4' : 1,
        animation: isTasksLoading
          ? `${pulseAnimation} 1.8s ease-in-out infinite`
          : 'unset',
      }}
    >
      <InfoBlockHeader
        chips={chips}
        startIcon={taskStatus}
        actions={!isMobileScreen ? actions : null}
      />

      <Divider />

      <InfoBlockContent
        isShowOverlay={expandedCondition}
        onClickToggleOverlay={() => setExpandedCondition(true)}
      >
        <Box className='hover-content'>
          {topics && (
            <Typography
              sx={{
                color: '#131920DE',
                mb: '8px',
                whiteSpace: isMobileScreen
                  ? 'normal'
                  : expandedCondition
                    ? 'normal'
                    : 'nowrap',
                textOverflow: isMobileScreen
                  ? 'clip'
                  : expandedCondition
                    ? 'clip'
                    : 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100%',
              }}
              fontWeight={400}
              fontSize='14px'
              lineHeight='24px'
              letterSpacing='0.17px'
            >
              <span style={{ fontWeight: '500' }}>Темы:</span> {topics}
            </Typography>
          )}

          {item.group?.content && (
            <>
              <Typography
                sx={{ color: '#131920DE', marginBottom: '2px' }}
                fontWeight={500}
                fontSize='14px'
                lineHeight='24px'
                letterSpacing='0.17px'
              >
                Общее условие
              </Typography>

              <Box
                sx={{
                  overflowX: 'auto',
                  overflow: 'hidden',
                  height: '100%',
                  '& p': {
                    marginBlockStart: '0',
                    marginBlockEnd: '0',
                  },
                  '& img': {
                    width: { xs: 'max-content', sm: 'auto' },
                    maxWidth: { xs: '100% !important', sm: 'unset' },
                    height: 'auto',
                  },
                  ...(hideCondition &&
                    !expandedCondition && {
                      ...conditionStyle,
                    }),
                }}
                dangerouslySetInnerHTML={{ __html: item.group?.content }}
                ref={conditionRef}
              />
            </>
          )}

          {item.conditions && (
            <>
              <Typography
                sx={{ color: '#131920DE', marginBottom: '2px' }}
                fontWeight={500}
                fontSize='14px'
                lineHeight='24px'
                letterSpacing='0.17px'
              >
                Условие
              </Typography>

              <Box
                sx={{
                  overflowX: 'auto',
                  overflow: 'hidden',
                  height: '100%',
                  marginBottom: '8px',
                  '& p': {
                    marginBlockStart: '0',
                    marginBlockEnd: '0',
                    wordBreak: 'break-word !important',
                  },
                  '& img': {
                    width: { xs: 'max-content', sm: 'auto' },
                    maxWidth: { xs: '100% !important', sm: 'unset' },
                    height: 'auto',
                  },
                  ...(hideCondition2 &&
                    !expandedCondition && {
                      ...conditionStyle,
                    }),
                }}
                dangerouslySetInnerHTML={{ __html: item.conditions }}
                ref={conditionRef2}
              />
            </>
          )}

          {expandedCondition && (
            <>
              {item.hints &&
                item.hints.length > 0 &&
                item.hints.map((hint, index) => {
                  return (
                    hint.content && (
                      <Box sx={{ mb: 1 }} key={hint.id}>
                        <Typography
                          sx={{
                            color: '#131920DE',
                            fontSize: '14px',
                            lineHeight: '24px',
                            letterSpacing: '0.17px',
                            fontWeight: 500,
                          }}
                        >
                          {index + 1} подсказка
                        </Typography>
                        <Box
                          dangerouslySetInnerHTML={{ __html: hint.content }}
                          sx={{
                            overflowX: 'auto',
                            overflow: 'hidden',
                            '& p': {
                              marginBlockStart: '0',
                              marginBlockEnd: '0',
                            },
                          }}
                        />
                      </Box>
                    )
                  )
                })}

              {item.solution && (
                <Box sx={{ mb: 1 }}>
                  <Typography
                    sx={{
                      color: '#131920DE',
                      fontSize: '14px',
                      lineHeight: '24px',
                      letterSpacing: '0.17px',
                      fontWeight: 500,
                    }}
                  >
                    Решение
                  </Typography>

                  <Box
                    dangerouslySetInnerHTML={{ __html: item.solution }}
                    sx={{
                      overflowX: 'auto',
                      overflow: 'hidden',
                      '& p': {
                        marginBlockStart: '0',
                        marginBlockEnd: '0',
                      },
                      '& img': {
                        width: { xs: 'max-content', sm: 'auto' },
                        maxWidth: { xs: '100% !important', sm: 'unset' },
                        height: 'auto',
                      },
                    }}
                  />
                </Box>
              )}

              <Typography
                sx={{
                  color: '#131920DE',
                  fontSize: '14px',
                  lineHeight: '24px',
                  letterSpacing: '0.17px',
                  fontWeight: 500,
                }}
              >
                {variantAnswerText}・{scoreAnswerText}
              </Typography>

              {item.type === 'selection' &&
                'variants' in item &&
                item.variants &&
                item.variants.map((variant, index) => {
                  return (
                    variant.content && (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: '4px',
                          //@ts-ignore
                          mb: item.variants.length - 1 !== index ? '2px' : 0,
                        }}
                      >
                        {variant.isCorrect ? (
                          <CheckIcon
                            color='success'
                            sx={{ fontSize: '16px', mt: '4px' }}
                          />
                        ) : (
                          <CloseIcon color='error' sx={{ fontSize: '16px', mt: '4px' }} />
                        )}
                        <Box
                          dangerouslySetInnerHTML={{ __html: variant.content }}
                          sx={{
                            '& p': {
                              marginBlockStart: '0',
                              marginBlockEnd: '0',
                            },
                          }}
                        />
                      </Box>
                    )
                  )
                })}
              {item.type === 'manual' && (
                <Typography
                  sx={{
                    color: '#131920DE',
                    fontSize: '14px',
                    lineHeight: '24px',
                    letterSpacing: '0.17px',
                    mb: 1,
                    fontWeight: 400,
                  }}
                >
                  Ожидаемый ввод — {expectedTypeAnswerManul}.
                </Typography>
              )}

              {item.type === 'text' && (
                <Box sx={{ mb: 1 }}>
                  <Typography>
                    Ожидаемый ввод — {expectedTypeAnswerText}.<br />
                    {'correctAnswer' in item &&
                      item.correctAnswer &&
                      ` Правильный ответ: «${item.correctAnswer}» `}
                    {'synonyms' in item && item.synonyms && item.synonyms.length > 0 && (
                      <Typography component='span' sx={{ color: 'rgba(0, 0, 0, 0.26)' }}>
                        (синонимы{' '}
                        {item.synonyms.map((el, index) => (
                          <Fragment key={index}>
                            <span>«{el}»</span>
                            {index < (item.synonyms?.length || 0) - 1 && ', '}
                          </Fragment>
                        ))}
                        )
                      </Typography>
                    )}
                  </Typography>
                </Box>
              )}

              {item.sources && item.sources.length > 0 && (
                <Typography
                  sx={{
                    color: '#131920DE',
                    fontSize: '14px',
                    lineHeight: '24px',
                    letterSpacing: '0.17px',
                    mb: 1,
                    fontWeight: 500,
                  }}
                >
                  Источники:{' '}
                  {item.sources.map((source, index) => (
                    <Fragment key={source.id}>
                      <span style={{ fontWeight: 400 }}>{source.name}</span>
                      {index < (item.sources?.length || 0) - 1 && ', '}
                    </Fragment>
                  ))}
                </Typography>
              )}
            </>
          )}
        </Box>
      </InfoBlockContent>

      <InfoBlockFooter footerStart={taskStartStatFooter} footerEnd={taskEndFooter} />

      {isMobileScreen && (
        <>
          <Divider />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              {actions?.map((action, index) => <Fragment key={index}>{action}</Fragment>)}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              {mobileActions?.map((action, index) => (
                <Fragment key={index}>{action}</Fragment>
              ))}
            </Box>
          </Box>
        </>
      )}
    </InfoBlock>
  )
}
