import { SyntheticEvent, useCallback, useState } from 'react'
import { StudentForSelectorDTO, StudentService } from '../../generated/api'
import ApiAutocomplete from '../extended/ApiAutocomplete'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { TextFieldProps, TextFieldVariants } from '@mui/material/TextField/TextField'
import UserAvatar from '../custom/UserAvatar'
import { Box, CircularProgress, MenuItem } from '@mui/material'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import AddNewStudent from '../custom/AddNewStudent'

interface StudentAutocompleteProps
  extends Omit<TextFieldProps, 'onChange' | 'value' | 'error'> {
  value: any
  multiple: boolean
  onChange: (event: SyntheticEvent, value: any) => void
  subjectId?: number
  error?: React.ReactNode
  label?: string
  variant?: TextFieldVariants
  marginLeft?: string
  onOpenAddNewStudent?: () => void
  onCloseAddNewStudent?: () => void
  onAddNewStudent?: (student: any) => void
  isOpenAddNewStudent?: boolean
  isAllowedAddNewStudent?: boolean
  disableClearable?: boolean
}

export default function StudentAutocomplete({
  value,
  multiple,
  onChange,
  error,
  subjectId,
  label = 'Ученик',
  marginLeft = '0',
  onOpenAddNewStudent,
  onCloseAddNewStudent,
  isOpenAddNewStudent,
  isAllowedAddNewStudent = false,
  onAddNewStudent,
  disabled,
  disableClearable = false,
  ...props
}: StudentAutocompleteProps) {
  const [isLoading, setIsLoading] = useState(false)
  const loadStudents = useCallback(
    ({ query, ids }: { query?: string; ids?: number[] }) => {
      setIsLoading(true)
      return StudentService.getAppApiUserStudentcrudGetlistforselect({
        idsArray: ids,
        query: query,
        subjectId: subjectId?.toString() || null,
      })
        .then((response) => response)
        .finally(() => setIsLoading(false))
    },
    [subjectId]
  )

  return (
    <>
      <ApiAutocomplete
        disableClearable={disableClearable}
        disabled={disabled}
        multiple={multiple}
        load={loadStudents}
        options={[]}
        labelField={'name'}
        valueField={'id'}
        renderOption={(props, option: StudentForSelectorDTO) => {
          return option.name ? (
            //@ts-ignore
            <Box
              sx={{
                display: 'flex',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
                padding: '8px 8px 8px 16px',
                overflow: 'unset !important',
              }}
              component='div'
              {...props}
            >
              <Box sx={{ mr: '12px' }}>
                <UserAvatar
                  name={option.name.trim()}
                  size='24px'
                  fontSize='10px'
                  lineHeight='12px'
                />
              </Box>
              <Box>{option.name}</Box>
              {option?.phone && (
                <Box sx={{ ml: '4px', color: '#13192061' }}>{option?.phone}</Box>
              )}
            </Box>
          ) : null
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            error={!!error}
            helperText={error}
            InputLabelProps={{
              shrink: !!value || !!params.inputProps.value,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {typeof params.inputProps.value === 'string' &&
                  value &&
                  params.inputProps.value ? (
                    <UserAvatar name={params.inputProps.value} marginLeft={marginLeft} />
                  ) : null}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            {...props}
          />
        )}
        onChange={onChange}
        formValue={value}
        PaperComponent={({ children }) => (
          <Paper
            sx={{ p: 0, minWidth: isLoading ? 'unset' : { xs: '100%', sm: '500px' } }}
            elevation={1}
          >
            <MenuList sx={{ m: 0, p: 0, width: '100%' }}>
              {isLoading ? (
                <Box
                  sx={{
                    width: '100%',
                    display: 'block',
                    padding: '6px 0',
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {React.Children.map(children, (child, index) => {
                    return child ? (
                      <MenuItem
                        key={index}
                        sx={{ m: 0, p: 0, width: '100%', display: 'block' }}
                      >
                        {child}
                      </MenuItem>
                    ) : null
                  })}
                  {isAllowedAddNewStudent && (
                    <MenuItem
                      sx={{ width: '100%', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
                    >
                      <Button
                        onMouseDown={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          if (onOpenAddNewStudent) {
                            onOpenAddNewStudent()
                          }
                        }}
                        sx={{
                          p: 0,
                          textTransform: 'inherit',
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#000000DE',
                        }}
                        startIcon={<AddIcon sx={{ color: '#1319208F' }} />}
                      >
                        Пригласить нового ученика
                      </Button>
                    </MenuItem>
                  )}
                </>
              )}
            </MenuList>
          </Paper>
        )}
      />
      <AddNewStudent
        isOpenAddNewStudent={isOpenAddNewStudent}
        onClose={onCloseAddNewStudent}
        onSave={onAddNewStudent}
      />
    </>
  )
}
