import TextField from '@mui/material/TextField'
import ApiAutocomplete from '../extended/ApiAutocomplete'
import * as React from 'react'
import { SyntheticEvent, useCallback, useState } from 'react'
import { ExamTypeService } from '../../generated/api'
import { TextFieldProps, TextFieldVariants } from '@mui/material/TextField/TextField'
import { Box, Chip, CircularProgress, MenuItem } from '@mui/material'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'

interface ExamTypeAutocompleteProps
  extends Omit<TextFieldProps, 'onChange' | 'value' | 'error'> {
  value: any
  multiple: boolean
  onChange: (event: SyntheticEvent, value: any) => void
  error?: React.ReactNode
  label?: string
  variant?: TextFieldVariants
}

export default function ExamTypeAutocomplete({
  value,
  multiple,
  onChange,
  error,
  label = 'Экзамен',
  ...props
}: ExamTypeAutocompleteProps) {
  const [isLoading, setIsLoading] = useState(false)
  const load = useCallback(({ query, ids }: { query?: string; ids?: number[] }) => {
    setIsLoading(true)
    return ExamTypeService.getAppApiReferencesExamtypecrudGetlist({
      idsArray: ids,
      query: query,
      limit: 100,
      offset: 0,
    })
      .then((response) => response.items || [])
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <ApiAutocomplete
      multiple={multiple}
      load={load}
      options={[]}
      labelField={'name'}
      valueField={'id'}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={!!error}
          helperText={error}
          {...props}
        />
      )}
      onChange={onChange}
      formValue={value}
      renderTags={(value, tagProps) =>
        value.map((option, index) => (
          <Chip
            {...tagProps({ index })}
            label={option.name}
            size='small'
            sx={{
              height: 20,
            }}
          />
        ))
      }
      PaperComponent={({ children }) => (
        <Paper sx={{ p: 0 }} elevation={1}>
          <MenuList sx={{ m: 0, p: 0, width: '100%' }}>
            {isLoading ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'block',
                  padding: '6px 0',
                  textAlign: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              React.Children.map(children, (child, index) => {
                return child ? (
                  <MenuItem
                    key={index}
                    sx={{
                      m: 0,
                      p: 0,
                      width: '100%',
                      display: 'block',
                      whiteSpace: 'normal',
                    }}
                  >
                    {child}
                  </MenuItem>
                ) : null
              })
            )}
          </MenuList>
        </Paper>
      )}
    />
  )
}
