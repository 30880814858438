import { FormikProvider, useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { ApiError, StudentDTO, UsersImportService } from '../../generated/api'
import formErrorHandle from '../../helpers/FormErrorHandler'
import InstructionForStudent from './InstructionForStudent'
import Dialog from '../custom/Dialog/Dialog'

interface IAddNewStudentProps {
  isOpenAddNewStudent?: boolean
  onClose?: () => void
  onSave?: (student: any) => void
  onSaveCloseDialog?: () => void
}

export default function AddNewStudent(props: IAddNewStudentProps) {
  const {
    onClose,
    isOpenAddNewStudent = false,
    onSave: onSaveAddStudent,
    onSaveCloseDialog,
  } = props
  const [isSubmit, setIsSubmit] = useState(false)

  const [user, setUser] = useState<StudentDTO | null>(null)
  const [isOpenInstruction, setIsOpenInstruction] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: null,
      class: 'Не указан',
    },
    onSubmit: (values: any) => {
      const editableValues = {
        name: values.name,
        class: typeof values.class === 'number' ? values.class : null,
      }

      setIsSubmit(true)
      UsersImportService.postAppApiUserImportActioninvitestudent({
        requestBody: editableValues,
      })
        .then((user) => {
          setUser(user)
          setIsOpenInstruction(true)
        })
        .catch((err: ApiError) => {
          formErrorHandle(err, formik)
        })
        .finally(() => setIsSubmit(false))
    },
  })

  const onCloseInstruction = useCallback(() => {
    formik.resetForm()
    onSaveAddStudent && onSaveAddStudent(user)
    onClose && onClose()
    onSaveCloseDialog && onSaveCloseDialog()
    setIsOpenInstruction(false)
  }, [user, formik, onClose, onSaveCloseDialog, onSaveAddStudent])

  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth='sm' open={isOpenAddNewStudent} onClose={onClose}>
        <DialogContent>
          <Box sx={{ p: { xs: '24px 16px', sm: 3 } }}>
            <Typography variant='h5' fontWeight='500'>
              Новый ученик
            </Typography>
          </Box>

          <Divider />

          <Stack spacing={2} sx={{ p: { xs: 2, sm: 3 } }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
              <TextField
                size='medium'
                name='name'
                fullWidth
                margin='dense'
                label='Имя Фамилия'
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                required
              />
            </Box>
            <FormControl fullWidth required>
              <InputLabel id='class-label'>Класс</InputLabel>
              <Select
                labelId='class-label'
                size='medium'
                label='Класс'
                value={formik.values.class}
                name='class'
                required
                fullWidth
                onChange={(event) => formik.setFieldValue(`class`, event.target.value)}
              >
                <MenuItem value='Не указан'>Не указан</MenuItem>
                {Array.from({ length: 11 }, (_, i) => i + 1).map((el) => (
                  <MenuItem value={el} key={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions
          sx={{
            padding: { xs: 2, sm: '18px 24px' },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 0 },
            '& > :not(:first-of-type)': {
              ml: { xs: 0, sm: 1 },
            },
          }}
        >
          <Button
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            disabled={isSubmit}
            onClick={onClose}
            color='primary'
          >
            Закрыть
          </Button>

          <Button
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            variant='contained'
            onClick={formik.submitForm}
            disabled={isSubmit}
          >
            Добавить ученика
          </Button>
        </DialogActions>
      </Dialog>

      {isOpenInstruction && user && (
        <InstructionForStudent
          onClose={onCloseInstruction}
          open={isOpenInstruction}
          user={user}
        />
      )}
    </FormikProvider>
  )
}
