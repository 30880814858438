import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  ProgramForSelectDTO,
  ProgramStudentDTO,
  ProgramStudentsService,
  StreamEducationDTO,
} from '../../../generated/api'
import { LoadingButton } from '@mui/lab'
import { Box, Divider, IconButton, List, ListItem, Typography } from '@mui/material'
import ProgramsAutocomplete from '../../autocomplete/ProgramsAutocomplete'
import { DatePicker } from '@mui/x-date-pickers'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '../../custom/Dialog/Dialog'
import dayjs from 'dayjs'

export default function StudentProgramsDialog({
  id,
  onClose,
  onSave,
}: {
  id: number
  onClose: () => void
  onSave: () => void
}) {
  const title = useMemo(() => {
    return 'Редактирование программ ученика'
  }, [id])

  const [programs, setPrograms] = useState<ProgramStudentDTO[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedProgram, setSelectedProgram] = useState<ProgramForSelectDTO | null>(null)

  useEffect(() => {
    setIsLoading(true)
    ProgramStudentsService.getAppApiCalendarProgramstudentcrudGetbystudentlist({
      studentId: id.toString(),
    })
      .then((response) => {
        setPrograms(response)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id])

  const onSubmitClick = useCallback(() => {
    setIsLoading(true)
    ProgramStudentsService.putAppApiCalendarProgramstudentcrudSavebystudentlist({
      studentId: id.toString(),
      requestBody: {
        programs: programs.map((program) => {
          return {
            programId: program.program?.id || 0,
            isActive: program.isActive || false,
            deactivatedAt: program.deactivatedAt || null,
          }
        }),
      },
    })
      .then(() => {
        onSave()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [programs, id, onSave])

  const selectedProgramChange = useCallback(
    (event: any, program: any) => {
      setSelectedProgram(program)
    },
    [setSelectedProgram]
  )

  const onAddClick = useCallback(() => {
    setPrograms([
      ...programs,
      {
        program: {
          ...selectedProgram,
        },
        isActive: true,
      },
    ])
  }, [selectedProgram, programs])

  const [isDeleteConfirm, setIsDeleteConfirm] = useState<boolean>(false)
  const [indexProgramForDelete, setIndexProgramForDelete] = useState<number | null>(null)

  const tryDeleteProgramStudent = useCallback(
    (index: number) => {
      setIsDeleteConfirm(true)
      setIndexProgramForDelete(index)
    },
    [programs]
  )

  const onDeleteProgram = useCallback(() => {
    setPrograms(programs.filter((program, i) => i !== indexProgramForDelete))
    setIsDeleteConfirm(false)
    setIndexProgramForDelete(null)
  }, [programs, indexProgramForDelete, setIsDeleteConfirm, setIndexProgramForDelete])

  const onDeleteProgramCanceled = useCallback(() => {
    setIsDeleteConfirm(false)
    setIndexProgramForDelete(null)
  }, [setIsDeleteConfirm, setIndexProgramForDelete])

  return (
    <>
      <Dialog fullWidth maxWidth={'sm'} open={true} onClose={onClose}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h5' fontWeight='500' sx={{ p: { xs: 2, sm: 3 } }}>
            {title}
          </Typography>

          <Divider />

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              p: { xs: 2, sm: 3 },
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'unset', sm: 'center' },
            }}
          >
            <Box sx={{ width: '100%' }}>
              <ProgramsAutocomplete
                multiple={false}
                fullWidth
                value={selectedProgram}
                onChange={selectedProgramChange}
                excludeIds={programs.map((program) => program.program?.id || 0)}
              />
            </Box>

            <Button
              variant='contained'
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              onClick={onAddClick}
              disabled={!selectedProgram}
            >
              Добавить
            </Button>
          </Box>

          <Divider />

          <List sx={{ p: 0 }}>
            {programs.map((programStudent, index) => (
              <Box key={programStudent.program?.id}>
                <ListItem disablePadding sx={{ p: 0 }}>
                  <Box
                    sx={{
                      p: { xs: 2, sm: 3 },
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '12px',
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: 'inline',
                          fontSize: '18px',
                          lineHeight: '24px',
                          fontWeight: 400,
                          color: programStudent.isActive ? '#40BB45' : '#d32f2f',
                        }}
                      >
                        ●
                      </Box>{' '}
                      {programStudent.program?.subject?.name && (
                        <>
                          <Box
                            sx={{
                              display: 'inline',
                              fontSize: '18px',
                              lineHeight: '24px',
                              fontWeight: 400,
                            }}
                          >
                            {programStudent.program?.subject?.name}
                          </Box>{' '}
                        </>
                      )}
                      {programStudent?.program &&
                        programStudent.program?.streamsEducation &&
                        programStudent.program?.streamsEducation?.length > 0 && (
                          <>
                            <Box
                              sx={{
                                display: 'inline',
                                fontSize: '18px',
                                lineHeight: '24px',
                                fontWeight: 400,
                              }}
                            >
                              {programStudent.program?.streamsEducation
                                ?.map((stream: StreamEducationDTO) => stream.name)
                                ?.join(', ')}
                            </Box>{' '}
                          </>
                        )}
                      {programStudent.program?.name && (
                        <>
                          <Box
                            sx={{
                              display: 'inline',
                              fontSize: '18px',
                              lineHeight: '24px',
                              fontWeight: 400,
                            }}
                          >
                            {programStudent.program?.name}
                          </Box>{' '}
                        </>
                      )}
                      {programStudent.program?.target && (
                        <>
                          <Box
                            sx={{
                              display: 'inline',
                              fontSize: '18px',
                              lineHeight: '24px',
                              fontWeight: 400,
                            }}
                          >
                            ({programStudent.program?.target}) -{' '}
                          </Box>{' '}
                        </>
                      )}
                      {programStudent.program?.examType?.name && (
                        <>
                          <Box
                            sx={{
                              display: 'inline',
                              fontSize: '18px',
                              lineHeight: '24px',
                              fontWeight: 400,
                            }}
                          >
                            {programStudent.program?.examType?.name}
                          </Box>
                        </>
                      )}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <DatePicker
                        sx={{ width: { xs: '100%', sm: '212px' } }}
                        label='Дата отключения'
                        //@ts-ignore
                        value={
                          programStudent.deactivatedAt
                            ? dayjs(programStudent.deactivatedAt)
                            : null
                        }
                        onChange={(value: string | null) => {
                          programStudent.deactivatedAt = value
                          setPrograms([...programs])
                        }}
                        slotProps={{
                          field: { clearable: true },
                          actionBar: {
                            actions: ['clear', 'cancel', 'accept'],
                            sx: {
                              justifyContent: 'space-between !important',
                              padding: '0 12px 8px !important',
                              '& .MuiButton-root': {
                                p: 0,
                              },
                              '& .MuiButton-root:first-child': {
                                color: 'error.main',
                              },
                            },
                          },
                        }}
                        localeText={{
                          clearButtonLabel: 'Очистить',
                          cancelButtonLabel: 'Отмена',
                          okButtonLabel: 'применить',
                        }}
                      />
                      <IconButton
                        onClick={() => tryDeleteProgramStudent(index)}
                        edge='end'
                        aria-label='delete'
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </ListItem>
                {programs.length - 1 !== index && <Divider />}
              </Box>
            ))}
          </List>

          <Divider />

          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', sm: 'auto' },
              justifyContent: 'space-between',
              marginTop: 'auto',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 2, sm: 0 },
              p: { xs: 2, sm: 3 },
              '& > :not(:first-of-type)': {
                ml: 0,
              },
            }}
          >
            <Button
              color='error'
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              onClick={onClose}
            >
              отмена
            </Button>
            <LoadingButton
              variant='contained'
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              loading={isLoading}
              onClick={onSubmitClick}
            >
              Сохранить
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isDeleteConfirm}>
        <DialogTitle></DialogTitle>
        <Typography variant='h5' fontWeight='500' sx={{ p: 3 }}>
          Удаление программы
        </Typography>

        <Divider />

        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ p: 3 }}>
            Все данные и статистика ученика по этой программе будут потеряны. Вы уверены,
            что хотите удалить программу?
          </Box>

          <Divider />

          <Box
            sx={{
              alignSelf: { xs: 'unset', sm: 'flex-end' },
              width: { xs: '100%', sm: 'auto' },
              marginTop: 'auto',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 2, sm: 0 },
              p: { xs: 2, sm: 3 },
              '& > :not(:first-of-type)': {
                ml: 0,
              },
            }}
          >
            <Button
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              onClick={onDeleteProgramCanceled}
            >
              Нет
            </Button>
            <Button sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={onDeleteProgram}>
              Да
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
