import Avatar from '@mui/material/Avatar'
import * as React from 'react'
import { tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiTooltip from '@mui/material/Tooltip'
import { stringToColor } from '../../helpers/stringToColor'

export function stringAvatar(
  name: string,
  marginLeft: string,
  size: string,
  fontSize: string,
  lineHeight: string,
  fontWeight: number
) {
  const names = name.trim().split(' ')
  return {
    sx: {
      bgcolor: stringToColor(name),
      '&.MuiAvatar-root': {
        width: size,
        height: size,
        color: 'white',
        fontSize: fontSize,
        lineHeight: lineHeight,
        marginLeft: marginLeft,
        fontWeight: fontWeight,
        padding: '0 4px',
        textTransform: 'uppercase',
      },
      '&.MuiAvatar-root: first-child': {
        zIndex: 1,
      },
    },
    children: `${names[0][0]}${names.length > 1 ? names[1][0] : ''}`,
  }
}

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 10,
    lineHeight: '14px',
    fontWeight: 500,
  },
}))

interface IUserAvatarProps {
  name: string
  marginLeft?: string
  size?: string
  fontSize?: string
  lineHeight?: string
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>
  tooltipTitle?: string
  fontWeight?: number
}

export default function UserAvatar(props: IUserAvatarProps) {
  const {
    name,
    marginLeft = '0',
    size = '16px',
    fontSize = '8px',
    lineHeight = '10px',
    tooltipTitle,
    tooltipProps,
    fontWeight = 500,
  } = props

  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : undefined} {...tooltipProps}>
      <Avatar
        {...stringAvatar(name, marginLeft, size, fontSize, lineHeight, fontWeight)}
      />
    </Tooltip>
  )
}
