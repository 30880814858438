import React, { useContext, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  useMediaQuery,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { ProfileContext } from '../../App'
import { useUchebaProfile } from '../../helpers/useUchebaProfile'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import TelegramIcon from '@mui/icons-material/Telegram'
import theme from '../../styles/globalTheme'

interface ITgAlertProviderProps {
  children: any
  open?: boolean
  onClose?: () => void
}

const TgAlertProvider: React.FC<ITgAlertProviderProps> = (props) => {
  const CHANNEL_ID = 4
  const { children, onClose } = props
  const [open, setOpen] = useState(false)
  const profile = useContext(ProfileContext)
  const { uchebaProfile } = useUchebaProfile()
  const [openDialog, setOpenDialog] = useState(false)

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const messangers = useMemo(() => {
    if (uchebaProfile) {
      return uchebaProfile.messengers
    }

    return null
  }, [uchebaProfile])

  const hasChannel = useMemo(() => {
    if (messangers) {
      return messangers.some((el) => el.channelId === CHANNEL_ID && el.isAllowed)
    }

    return null
  }, [messangers])

  useEffect(() => {
    if (!open && hasChannel) {
      if (onClose) {
        onClose()
      }
    }
  }, [hasChannel])

  useEffect(() => {
    if (profile?.cabinet?.id !== 1) {
      if (messangers) {
        if (!hasChannel) {
          setOpenDialog(true)
        } else {
          setOpenDialog(false)
        }
      }
    }
  }, [messangers, profile, hasChannel])

  useEffect(() => {
    if (profile?.cabinet?.id !== 1) {
      if (messangers) {
        if (!hasChannel && !openDialog) {
          setOpen(true)
        } else {
          setOpen(false)
        }
      }
    }
  }, [messangers, profile, hasChannel, openDialog])

  const href = useMemo(() => {
    return `https://www.ucheba.ru/events/tg-subscribe/registration?channelId=${CHANNEL_ID}&templateId=10118`
  }, [])

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          minHeight: '100%',
        }}
      >
        {children}
      </div>

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)

          if (onClose) {
            onClose()
          }
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiPaper-root': {
            margin: 2,
          },
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          fontSize={'24px'}
          sx={{ p: { xs: '24px 16px', sm: '16px 24px' } }}
        >
          Чтобы продолжить работу подключите наши уведомления в телеграме
        </DialogTitle>

        <Divider />

        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            sx={{ p: { xs: '24px 16px', sm: '16px 24px' } }}
            color={'black'}
            fontSize={'14px'}
          >
            Будем напоминать о предстоящих занятиях и сообщать о выполненных работах.
            Обещаем не спамить 🙂
          </DialogContentText>
        </DialogContent>

        <Divider />

        <DialogActions
          sx={{
            padding: { xs: 2, sm: 3 },
          }}
        >
          <Button
            target={'_blank'}
            sx={{
              fontSize: '15px !important',
              lineHeight: '24px',
              fontWeight: 500,
              height: { xs: '40px', sm: '48px' },
              p: '8px 9.5px',
            }}
            href={href}
            color='primary'
            variant='contained'
            fullWidth
            size='small'
            endIcon={!isMobileScreen && <TelegramIcon />}
          >
            Подключить уведомления
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          marginTop: '24px',
          padding: { xs: '0 16px 24px', sm: '0 0 24px 0' },
          marginBottom: '-24px',
          display: open ? 'block' : 'none',
          width: '100%',
          position: 'sticky',
          backgroundColor: '#F5F7F9',
          bottom: '0',
          zIndex: '10',
        }}
      >
        <a target={'_blank'} href={href} style={{ textDecoration: 'none' }}>
          <Box
            bgcolor={'rgba(29, 138, 254, 1)'}
            padding={'16px'}
            borderRadius={'8px'}
            position={'relative'}
            zIndex={10}
            onClick={() => {
              setOpen(false)
            }}
            sx={{
              boxShadow:
                '0px 6px 7px -4px rgba(19, 25, 32, 0.08), 0px 11px 15px 1px rgba(19, 25, 32, 0.04), 0px 4px 20px 3px rgba(19, 25, 32, 0.08)',
            }}
          >
            <Grid container justifyContent={'space-between'}>
              <Grid item alignItems={'center'} display={'flex'}>
                <Typography
                  color={'white'}
                  fontSize={'18px'}
                  fontWeight={500}
                  lineHeight={'24px'}
                >
                  {isMobileScreen
                    ? 'Подключить уведомления'
                    : 'Подключить уведомления о начале занятий, выполненных работах и других важных событиях'}
                </Typography>
              </Grid>

              <Grid item alignItems={'center'} display={'flex'}>
                <TelegramIcon sx={{ color: 'white' }} />
              </Grid>
            </Grid>
          </Box>
        </a>
      </Box>
    </div>
  )
}

export default React.memo(TgAlertProvider)
