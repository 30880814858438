import { Box } from '@mui/material'
import * as React from 'react'
import UserAvatar from '../../../custom/UserAvatar'
import { memo } from 'react'

interface CheckerAutocompleteOptionProps {
  id: string
  name: string
  options: Record<string, any>
}

const CheckerAutocompleteOption: React.FC<CheckerAutocompleteOptionProps> = (props) => {
  const { id, name, options } = props

  if (id === 'allowed') {
    return (
      //@ts-ignore
      <Box
        sx={{
          display: 'flex',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '8px 8px 8px 16px',
          overflow: 'unset !important',
        }}
        component='div'
        {...options}
      >
        <Box>{name}</Box>
      </Box>
    )
  }

  if (name) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '8px 8px 8px 16px',
          overflow: 'unset !important',
        }}
        component='div'
        {...options}
      >
        <Box sx={{ mr: '12px' }}>
          <UserAvatar name={name} size='24px' fontSize='10px' lineHeight='12px' />
        </Box>

        <Box>{name}</Box>
      </Box>
    )
  }

  return null
}

export default memo(CheckerAutocompleteOption)
