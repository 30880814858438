import { Autocomplete, AutocompleteValue } from '@mui/material'
import * as React from 'react'
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete'
import { ChipTypeMap } from '@mui/material/Chip'
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { isSingleSelectColDef } from '@mui/x-data-grid/internals'

export interface ApiAutocompleteProps<
  T,
  ValueField extends keyof T,
  LabelField extends keyof T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  Multiple extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> {
  valueField: ValueField | null
  labelField: LabelField
  load: ({ query, ids }: { query?: string; ids?: number[] }) => Promise<T[]>
  formValue: any
  options: never[]
  onChange: (event: SyntheticEvent, value: any) => void
  autoSelectIfSingle?: boolean
}

export default function ApiAutocomplete<
  T extends { [key: string | number]: string | any },
  ValueField extends keyof T,
  LabelField extends keyof T,
  DisableClearable extends boolean | undefined,
  Multiple extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  valueField,
  labelField,
  load,
  onChange,
  multiple,
  formValue: value,
  freeSolo: _freeSolo,
  options: _options,
  loading: _loading,
  value: _value,
  autoSelectIfSingle,
  ...props
}: ApiAutocompleteProps<
  T,
  ValueField,
  LabelField,
  DisableClearable,
  Multiple,
  Multiple,
  ChipComponent
>) {
  const [isLoading, setIsLoading] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [options, setOptions] = useState<T[]>([])
  const [inputValue, setInputValue] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<any>(multiple ? [] : null)
  useEffect(() => {
    if (!inputValue && !multiple && value) {
      return
    }
    if (!multiple && selectedOptions && inputValue === selectedOptions[labelField]) {
      return
    }
    const timeout = setTimeout(() => {
      setIsLoading(true)
      load({ query: inputValue })
        .then((response) => {
          setOptions(response)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 300)
    return () => clearTimeout(timeout)
  }, [load, inputValue])

  useEffect((): any => {
    if (!value || value.length === 0) {
      setSelectedOptions(multiple ? [] : null)
      return
    }
    const valueAsArray = multiple ? value : [value]
    const selectedOptionsAsArray = multiple
      ? selectedOptions
      : selectedOptions
        ? [selectedOptions]
        : []
    const deletedOptions = selectedOptionsAsArray.filter(
      (selectedOption: T) =>
        !options.find((option: T) =>
          valueField
            ? option[valueField] === selectedOption[valueField]
            : option === selectedOption
        )
    )
    const newSelectedOptions = valueAsArray
      .map((val: any) =>
        options
          .concat(deletedOptions)
          .find((option: T) => (valueField ? option[valueField] === val : option === val))
      )
      .filter(Boolean)
    if (newSelectedOptions.length === 0 && valueAsArray.length > 0) {
      load({
        ids: valueField ? valueAsArray : valueAsArray.map((val: any) => val['id']),
      }).then((response) => {
        if (response.length > 0) {
          setSelectedOptions(multiple ? response : response[0])
        }
      })
    } else {
      setSelectedOptions(multiple ? newSelectedOptions : newSelectedOptions[0])
    }
  }, [multiple, value, options, valueField])

  useEffect(() => {
    if (!autoSelectIfSingle) {
      return
    }
    if (options.length === 1 && !value && !inputValue) {
      onSelectionChange(null, options[0])
    }
  }, [options, value, inputValue, isFirstLoad])

  const onSelectionChange = useCallback(
    (event: SyntheticEvent | null, val: any) => {
      if (event === null) {
        event = {} as SyntheticEvent
      }
      onChange(
        event,
        multiple
          ? val?.map((v: any) => (valueField ? v[valueField] : v))
          : valueField
            ? val?.[valueField]
            : val
      )
      if (multiple) {
        setInputValue(inputValue)
      }
    },
    [multiple, onChange, valueField, inputValue]
  )

  const onOpenSelect = useCallback(() => {
    if (!isLoading) {
      load({ query: '' }).then((response) => setOptions(response))
    }
  }, [isLoading, load])

  return (
    <Autocomplete
      freeSolo={multiple}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) =>
        valueField ? option[valueField] === value[valueField] : option === value
      }
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onOpen={onOpenSelect}
      multiple={multiple}
      options={options}
      filterOptions={(options) => options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option[labelField]
      }
      onChange={onSelectionChange}
      value={selectedOptions}
      loading={isLoading}
      disableCloseOnSelect={multiple}
      {...props}
    />
  )
}
