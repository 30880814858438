import React, { ReactNode, useEffect, useRef } from 'react'
import { memo, useState } from 'react'
import styles from './styles.module.css'
import { MorePortalProps, MoreProps } from './types'
import { createPortal } from 'react-dom'

const MorePortal: React.FC<MorePortalProps> = ({ children, idRef, open }) => {
  return createPortal(
    <div id={idRef.current || undefined} className={styles.modal}>
      {children}
    </div>,
    document.body
  )
}

const More: React.FC<MoreProps> = (props) => {
  const { children, content } = props
  const [open, setOpen] = useState(false)
  const blockRef = useRef<HTMLDivElement>(null)
  const idRef = useRef<string>('morePortalId' + Date.now())

  useEffect(() => {
    if (blockRef.current) {
      const v = blockRef.current.getBoundingClientRect()

      const modalElement = document.getElementById(idRef.current)

      if (modalElement) {
        if (open) {
          modalElement.style.display = 'block'
          modalElement.style.position = 'fixed'
          modalElement.style.top =
            v.top - (modalElement.offsetHeight - blockRef.current.offsetHeight) + 'px'
          modalElement.style.left = v.left + (blockRef.current.offsetWidth + 8) + 'px'
        } else {
          modalElement.style.display = 'none'
        }
      }
    }
  }, [blockRef.current, open])

  return (
    <div className={styles.block}>
      <div
        ref={blockRef}
        className={styles.button_block}
        data-open={open}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {children}
      </div>

      {open && (
        <div
          className={styles.background}
          onClick={(event) => {
            console.log(event)
            setOpen(false)
          }}
        />
      )}

      <MorePortal idRef={idRef} open={open}>
        {content}
      </MorePortal>
    </div>
  )
}

export default memo(More)
