import { Dayjs } from 'dayjs'

// Возвращает дату в utc формате: 2025-03-25 18:59:59
export default function getUtcDateTime(date: Dayjs) {
  return date
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .subtract(date.utcOffset(), 'minute')
    .format('YYYY-MM-DD HH:mm:ss')
}
