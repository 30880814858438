import React, { memo, useEffect, useMemo } from 'react'
import Dialog from '../Dialog/Dialog'
import {
  VideoBlockProps,
  VideoInstructionsDialogProps,
  VideoInstructionsItem,
} from './types'
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  useMediaQuery,
  Snackbar,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import styles from './styles.module.css'
import theme from '../../../styles/globalTheme'
import LinkIcon from '@mui/icons-material/Link'
import { stringifyUrl } from '../../../helpers/router'
import { useNavigate, useSearchParams } from 'react-router-dom'

const VideoBlock: React.FC<VideoBlockProps> = (props) => {
  const {
    title,
    videoUrl,
    description,
    children,
    hash,
    button,
    onOpenClipboardSnackbar,
  } = props
  const [played, setPlayed] = React.useState(false)
  const ref = React.useRef<HTMLVideoElement>(null)

  const link = useMemo(() => {
    return stringifyUrl(window.location.origin + window.location.pathname, {
      set: {
        videoInstructionsDialog: hash || 'default',
      },
    })
  }, [hash])

  return (
    <div id={hash ? `video_block_id-${hash}` : undefined}>
      {title && (
        <Typography variant={'h6'} marginBottom={'4px'} display={'flex'} align={'center'}>
          <span>{title}</span>

          <span
            style={{
              display: 'inline-block',
              marginTop: '4px',
              marginLeft: '4px',
            }}
          >
            <LinkIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigator.clipboard
                  .writeText(link)
                  .then(() => {
                    onOpenClipboardSnackbar()
                  })
                  .catch((err) => {
                    console.log('Something went wrong', err)
                  })
              }}
            />
          </span>
        </Typography>
      )}

      {description && (
        <Typography
          fontSize={'16px'}
          dangerouslySetInnerHTML={{ __html: description }}
          marginBottom={'16px'}
        />
      )}

      <div
        style={{
          marginBottom: '24px',
        }}
      >
        <Card sx={{ display: 'flex' }} style={{ position: 'relative' }}>
          <video ref={ref} controls={played} width={'100%'}>
            <source src={videoUrl} type='video/mp4' />
          </video>

          {!played && (
            <div
              className={styles.VideoBlockPlayBackground}
              style={{}}
              onClick={() => {
                if (ref.current) {
                  ref.current.play()

                  setPlayed(true)
                }
              }}
            >
              <PlayCircleIcon
                style={{
                  color: 'white',
                }}
              />
            </div>
          )}
        </Card>

        {button && (
          <Box sx={{ marginTop: '8px' }}>
            <Button href={button.href} fullWidth variant={'contained'} color={'primary'}>
              {button.text}
            </Button>
          </Box>
        )}
      </div>

      {children &&
        children.length > 0 &&
        children.map((item, i) => (
          <Accordion
            square
            style={{
              borderRadius: '6px',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel-content'
            >
              {item.title && (
                <Typography
                  variant={'body1'}
                  fontWeight={500}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              )}
            </AccordionSummary>

            <AccordionDetails>
              <VideoBlock
                videoUrl={item.videoUrl}
                title={null}
                description={item.description}
                onOpenClipboardSnackbar={onOpenClipboardSnackbar}
              />
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  )
}

/* Поп-ап с видеоинструкциями */
const VideoInstructionsDialog: React.FC<VideoInstructionsDialogProps> = (props) => {
  const { open, onClose, onOpen } = props
  const [loading, setLoading] = React.useState(false)
  const [items, setItems] = React.useState<Array<VideoInstructionsItem>>([])
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [clipboard, setClipboard] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_UCHEBA_URL}/page_data/teachers_video_instructions`)
      .then((res) => {
        return res.json()
      })
      .then((data: Array<VideoInstructionsItem>) => {
        setItems(data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (open) {
      const target = searchParams.has('videoInstructionsDialog')
        ? searchParams.get('videoInstructionsDialog')
        : window.location.pathname.split('/')[1]

      if (target) {
        setTimeout(() => {
          const targetElement = document.getElementById(`video_block_id-${target}`)
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        }, 100) // Даем время на рендеринг
      }
    }
  }, [open])

  const onOpenClipboardSnackbar = () => {
    setClipboard(true)
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    if (searchParams.has('videoInstructionsDialog')) {
      if (onOpen) {
        onOpen()
      }

      timeout = setTimeout(() => {
        searchParams.delete('videoInstructionsDialog')

        navigate(window.location.pathname + searchParams.toString())
      }, 1000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Stack
        sx={{
          width: { md: '600px', xs: '100%' },
          position: 'relative',
          overflowY: isMobileScreen ? 'auto' : undefined,
        }}
      >
        {isMobileScreen && (
          <div
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
            }}
          >
            <CloseSharpIcon onClick={onClose} style={{ cursor: 'pointer' }} />
          </div>
        )}

        <Box padding={'24px'}>
          <Typography variant='h6'>Видеоинструкция</Typography>
        </Box>

        <Divider />

        <Box padding={'0 24px'}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {items.length > 0 ? (
                <div>
                  {items.map((item, index, arr) => {
                    return (
                      <Box key={index} paddingTop={'24px'}>
                        <VideoBlock
                          {...item}
                          onOpenClipboardSnackbar={onOpenClipboardSnackbar}
                        />

                        {arr.length - 1 !== index && <Divider />}
                      </Box>
                    )
                  })}
                </div>
              ) : (
                <Box padding={'24px 0'}>
                  <Typography variant={'h6'} textAlign={'center'}>
                    Пока что пусто
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>

        <Divider />

        <Box padding={'24px'} display={'flex'} justifyContent={'flex-end'}>
          <Button onClick={onClose} variant={'contained'} color={'primary'}>
            Готово
          </Button>
        </Box>
      </Stack>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={clipboard}
        onClose={() => {
          setClipboard(false)
        }}
        autoHideDuration={3000}
        message='Ссылка скопирована в буфер обмена'
      />
    </Dialog>
  )
}

export default memo(VideoInstructionsDialog)
