import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Dialog from '../Dialog/Dialog'
import { Box, CircularProgress, DialogContent, Divider, Stack } from '@mui/material'
import {
  ProgramStudentDTO,
  ProgramStudentsService,
  RegisterStudentRequest,
  StudentDTO,
  StudentService,
} from '../../../generated/api'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import StudentPrograms from './components/StudentPrograms'
import StudentInfo from './components/StudentInfo'
import { ProfileContext } from '../../../App'
import StudentProgramsDialog from '../../pages/students/StudentProgramsDialog'
import StudentEditDialog from '../../pages/students/StudentEditDialog'
import { buttonStyle } from './constants'

interface IAboutStudentDialogProps {
  studentId: number
  open: boolean
  onClose: () => void
}

export default function AboutStudentDialog(props: IAboutStudentDialogProps) {
  const { studentId, open, onClose } = props
  const profile = useContext(ProfileContext)
  const isSchoolTeacher = useMemo(() => {
    return profile?.cabinet?.type === 'school'
  }, [profile])
  const [programs, setPrograms] = useState<ProgramStudentDTO[]>([])
  const [student, setStudent] = useState<StudentDTO | null>(null)
  const [programsLoading, setProgramsLoading] = useState(false)
  const [studentLoading, setStudentLoading] = useState(false)
  const [editedStudent, setEditedStudent] = useState<RegisterStudentRequest>()

  // Открытие редактирование программ
  const [openEditProgram, setOpenEditProgram] = useState(false)
  const [openEditStudent, setOpenEditStudent] = useState(false)

  // Запрос списка программ
  const getStudentPrograms = useCallback(() => {
    setProgramsLoading(true)
    ProgramStudentsService.getAppApiCalendarProgramstudentcrudGetbystudentlist({
      studentId: studentId.toString(),
    })
      .then((res) => {
        setPrograms(res)
      })
      .finally(() => setProgramsLoading(false))
  }, [studentId])

  // Получение ученика по айди
  const getStudentById = useCallback(() => {
    setStudentLoading(true)
    StudentService.getAppApiUserStudentcrudGetbyid({ id: studentId })
      .then((res) => {
        setStudent(res)
      })
      .finally(() => setStudentLoading(false))
  }, [studentId])

  // Закрытие редактирования списка программ
  const onCloseEditProgram = useCallback(() => {
    setOpenEditProgram(false)
  }, [])

  // Закрытие редактирования ученика
  const onCloseEditStudent = useCallback(() => {
    setOpenEditStudent(false)
  }, [])

  // Сохранение редактирования списка программ
  const onSaveEditProgram = useCallback(() => {
    setOpenEditProgram(false)
    getStudentPrograms()
  }, [])

  // Сохранение редактирования ученика
  const onSaveEditStudent = useCallback(() => {
    setOpenEditStudent(false)
    getStudentById()
  }, [])

  const onStartEditStudent = useCallback(() => {
    if (student) {
      setOpenEditStudent(true)
      setEditedStudent({
        phone: student?.phone || '',
        name: student?.name || '',
        interestedExamTypeId: student?.interestedExamType?.id,
        subjectIds: student?.subjects?.map((r) => r.id || 0),
        class: student?.class,
        messengerLink: student?.messengerLink,
        curatorId: student?.curator?.id,
        parentName: student?.parentName,
        parentPhone: student?.parentPhone,
        parentEmail: student?.parentEmail,
        educationAccessLevel: student?.educationAccessLevel,
      })
    }
  }, [student])

  const goToDashboard = useCallback(() => {
    window.open(
      process.env.REACT_APP_SCHOOL_URL + `/dashboard?user_id=${student?.id || 0}`
    )
  }, [student?.id])

  useEffect(() => {
    if (isSchoolTeacher) {
      getStudentPrograms()
    }
    getStudentById()
  }, [])

  return (
    <>
      <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {studentLoading ? (
            <Box sx={{ margin: '0 auto', p: { xs: 2, sm: 3 } }}>
              <CircularProgress />
            </Box>
          ) : (
            student && (
              <Stack spacing={3} sx={{ p: { xs: '24px 16px', sm: 3 } }}>
                <StudentInfo student={student} isSchoolTeacher={isSchoolTeacher} />

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {isSchoolTeacher && (
                    <Button fullWidth sx={buttonStyle} onClick={goToDashboard}>
                      Статистика успеваемости
                    </Button>
                  )}
                  <Button fullWidth sx={buttonStyle} onClick={onStartEditStudent}>
                    Редактировать ученика
                  </Button>
                </Box>
              </Stack>
            )
          )}

          {isSchoolTeacher &&
            (programsLoading ? (
              <>
                <Divider />

                <Box sx={{ margin: '0 auto', p: { xs: 2, sm: 3 } }}>
                  <CircularProgress />
                </Box>
              </>
            ) : (
              <>
                <Divider />

                <Stack spacing={3} sx={{ p: { xs: '24px 16px', sm: 3 } }}>
                  <Stack spacing={2}>
                    <Typography fontWeight={500} fontSize={18}>
                      Программы
                    </Typography>

                    {programs.length > 0 &&
                      programs.map((program, index) => (
                        <StudentPrograms key={index} program={program} />
                      ))}
                  </Stack>

                  <Button
                    onClick={() => setOpenEditProgram(true)}
                    fullWidth
                    sx={buttonStyle}
                  >
                    Редактировать Программы
                  </Button>
                </Stack>
              </>
            ))}

          <Box
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', mt: 'auto' }}
          >
            <Divider />

            <Box sx={{ display: 'flex', justifyContent: 'end', p: { xs: 2, sm: 3 } }}>
              <Button
                onClick={onClose}
                color='primary'
                variant='contained'
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                готово
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {openEditProgram && (
        <StudentProgramsDialog
          id={studentId}
          onClose={onCloseEditProgram}
          onSave={onSaveEditProgram}
        />
      )}

      {openEditStudent && editedStudent && (
        <StudentEditDialog
          model={editedStudent}
          id={studentId || 0}
          onClose={onCloseEditStudent}
          onSave={onSaveEditStudent}
          isSchoolTeacher={isSchoolTeacher}
        />
      )}
    </>
  )
}
