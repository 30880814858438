import globalTheme from '../../../styles/globalTheme'

export const buttonStyle = {
  backgroundColor: globalTheme.palette.greyBlue150.main,
  color: '#131920DE',
  p: '12px 0',
  boxShadow: 'unset',
  '&.MuiButtonBase-root.MuiButton-root:hover': {
    backgroundColor: '#4a5b6b',
    boxShadow: 'unset',
    color: 'white',
  },
}
