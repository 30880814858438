import React, { useMemo } from 'react'
import { ClassTypeEnum, StudentDTO } from '../../../../generated/api'
import { Box, Chip, Stack } from '@mui/material'
import UserAvatar from '../../UserAvatar'
import Typography from '@mui/material/Typography'

interface IStudentInfoProps {
  student: StudentDTO
  isSchoolTeacher: boolean
}

export default function StudentInfo(props: IStudentInfoProps) {
  const { student, isSchoolTeacher } = props

  const educationAccessLevel = useMemo(() => {
    switch (student.educationAccessLevel) {
      case 'maximum':
        return 'Максимальный'
      case 'optimal':
        return 'Оптимальный'
      case 'standard':
        return 'Стандартный'
      default:
        return null
    }
  }, [student.educationAccessLevel])

  const studentClass = useMemo(() => {
    if (student.class) {
      switch (student.class) {
        case ClassTypeEnum.PRESCHOOL:
          return 'Дошкольник'
        case ClassTypeEnum.FINISHED_SCHOOL:
          return 'Закончил'
        default:
          return `${student.class} класс`
      }
    }
    return null
  }, [student.class])

  return (
    <Stack spacing={3}>
      <Box>
        <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap', mb: 1 }}>
          {student.curator?.name && isSchoolTeacher && (
            <Chip
              label={student.curator.name}
              avatar={
                <UserAvatar
                  size='16px'
                  name={student.curator.name}
                  fontSize='8px'
                  fontWeight={600}
                  lineHeight='10px'
                  marginLeft='4px'
                />
              }
              size='small'
              sx={{
                backgroundColor: '#F5F7F9',
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 400,
                '& .MuiChip-label': {
                  paddingLeft: '4px',
                },
              }}
            />
          )}

          {isSchoolTeacher && educationAccessLevel && (
            <Chip
              label={educationAccessLevel}
              size='small'
              sx={{
                backgroundColor: '#F5F7F9',
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 400,
              }}
            />
          )}

          {student?.interestedExamType?.name && isSchoolTeacher && (
            <Chip
              label={student.interestedExamType.name}
              size='small'
              sx={{
                backgroundColor: '#F5F7F9',
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 400,
              }}
            />
          )}

          {studentClass && (
            <Chip
              label={studentClass}
              size='small'
              sx={{
                backgroundColor: '#F5F7F9',
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 400,
              }}
            />
          )}
        </Box>

        <Box sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: '4px', left: 0 }}>
            <UserAvatar
              size='24px'
              fontSize='10px'
              lineHeight='12px'
              name={student?.name || ''}
              fontWeight={600}
            />
          </Box>
          <Typography
            fontSize='24px'
            lineHeight='32px'
            fontWeight={500}
            sx={{ ml: '32px' }}
          >
            {student?.name || ''}
          </Typography>
        </Box>

        {student.phone && (
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Typography
              color='primary'
              component='a'
              href={`tel:${student.phone}`}
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                textDecoration: 'none',
              }}
            >
              {student.phone}
            </Typography>

            {student.messengerLink && (
              <Typography
                color='primary'
                component='a'
                href={student.messengerLink}
                target='_blank'
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 400,
                  textDecoration: 'none',
                }}
              >
                {student.messengerLink}
              </Typography>
            )}
          </Box>
        )}
      </Box>

      {student.parentName && (
        <Box>
          <Box>
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 500,
                display: 'inline',
              }}
            >
              {student.parentName}
            </Typography>{' '}
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 400,
                display: 'inline',
                color: '#13192061',
              }}
            >
              родитель
            </Typography>
          </Box>
          {student.parentPhone && (
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Typography
                color='primary'
                component='a'
                href={`tel:${student.parentPhone}`}
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 400,
                  textDecoration: 'none',
                }}
              >
                {student.parentPhone}
              </Typography>

              {student.parentEmail && (
                <Typography
                  color='primary'
                  component='a'
                  href={`mailto:${student.parentEmail}`}
                  target='_blank'
                  sx={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 400,
                    textDecoration: 'none',
                  }}
                >
                  {student.parentEmail}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Stack>
  )
}
