import React, { SyntheticEvent } from 'react'
import { TextFieldProps, TextFieldVariants } from '@mui/material/TextField/TextField'
import TextField from '@mui/material/TextField'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'

interface EducationAccessLevelSelectProps
  extends Omit<SelectProps, 'onChange' | 'value' | 'error'> {
  value?: string | null
  onChange: (event: SelectChangeEvent<any>, value: any) => void
  label?: string
  variant?: TextFieldVariants
  multiple?: boolean
  error?: string
}

export default function EducationAccessLevelSelect(
  props: EducationAccessLevelSelectProps
) {
  const {
    variant,
    value,
    onChange,
    multiple = false,
    sx,
    label = 'Тариф',
    error,
    required,
    ...otherProps
  } = props

  const options = [
    { value: 'standard', name: 'Стандартный' },
    { value: 'optimal', name: 'Оптимальный' },
    { value: 'maximum', name: 'Максимальный' },
  ]

  return (
    <FormControl error={!!error} required={required}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        sx={sx}
        error={!!error}
        size='medium'
        multiple={multiple}
        value={value}
        onChange={(event) => onChange(event, event.target.value)}
        {...otherProps}
      >
        <MenuItem value={''}>
          <em>Без тарифа</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText sx={{ color: '#FF0829' }}>{error}</FormHelperText>}
    </FormControl>
  )
}
