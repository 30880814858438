import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Dialog from '../../../custom/Dialog/Dialog'
import Typography from '@mui/material/Typography'
import { Box, DialogContent, Divider, Snackbar, Stack } from '@mui/material'
import { testGenerateLink } from '../bll/constants'
import {
  CommonTestsService,
  EducationTestForListDTO,
  EducationTestService as TestService,
} from '../../../../generated/api'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import { LoadingButton } from '@mui/lab'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface ITestGenerateLinkDialogProps {
  test: EducationTestForListDTO
  open: boolean
  isMobileScreen: boolean
  // Если репетитор генерирует ссылку в "общей базе", сначала копирует в "мои"
  isCopyTest?: boolean
  onClose: () => void
  onSave?: (id?: number) => void
}

export default function TestGenerateLinkDialog(props: ITestGenerateLinkDialogProps) {
  const { test, open, isMobileScreen, isCopyTest = false, onClose, onSave } = props

  const title = useMemo(() => {
    return `Публичная ссылка на работу «${test.name}»`
  }, [test])

  const [openAlert, setOpenAlert] = useState(false)
  const [publicAccessUrl, setPublicAccessUrl] = useState<string | null>(null)
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  /** Если сгенерить ссылку в "общей базе" то удалить её можно если открыть заново в "мои"
   * сэтаю айдишник при копировании в "мои" чтобы была возможность удалить в этом же попапе
   * */
  const [newId, setNewId] = useState<number | null>(null)
  const onClickGetLink = useCallback(async () => {
    setIsLoadingButton(true)

    const callback = isCopyTest
      ? CommonTestsService.postAppApiEducationCommontestsCopy({ id: test.id || 0 })
      : TestService.postAppApiEducationEducationtestcrudActionpublicaccess({
          id: test.id || 0,
        })

    callback
      .then((res) => {
        if (isCopyTest && res) {
          TestService.postAppApiEducationEducationtestcrudActionpublicaccess({
            id: res?.id || 0,
          })
            .then((res) => {
              setNewId(res?.id || 0)
              if (res.publicAccessUrl) {
                setPublicAccessUrl(res.publicAccessUrl)
              }
              // Когда генерируем ссылку на странице просмотра; из общего банка
              if (onSave) {
                onSave(res?.id || 0)
              }
            })
            .finally(() => setIsLoadingButton(false))
        }
        if (!isCopyTest && res.publicAccessUrl) {
          setPublicAccessUrl(res.publicAccessUrl)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        if (!isCopyTest) {
          setIsLoadingButton(false)
        }
      })
  }, [isCopyTest])

  const deleteLinkId = useMemo(() => {
    if (test.id && !isCopyTest) {
      return test.id
    }
    return newId || 0
  }, [newId])

  const onClickDeleteLink = useCallback(async () => {
    setIsLoadingButton(true)
    await TestService.deleteAppApiEducationEducationtestcrudClosepublicaccess({
      id: deleteLinkId,
    })
      .then(() => {
        onClose()
        setPublicAccessUrl(null)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingButton(false))
  }, [deleteLinkId])

  const onCopyHref = useCallback(() => {
    if (publicAccessUrl) {
      navigator.clipboard
        .writeText(publicAccessUrl)
        .then(() => setOpenAlert(true))
        .catch((err) => {
          console.error('Ошибка копирования: ', err)
        })
    }
  }, [publicAccessUrl])

  useEffect(() => {
    if (test.publicAccessUrl) {
      setPublicAccessUrl(test.publicAccessUrl)
    }
  }, [])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 500,
            p: { xs: '24px 16px', sm: 3 },
          }}
        >
          {title}
        </Typography>

        <Divider />

        <Stack spacing={2} sx={{ p: { xs: '24px 16px', sm: 3 } }}>
          <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}>
            {testGenerateLink.text}
          </Typography>

          <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 500 }}>
            {testGenerateLink.text2}
          </Typography>

          {!publicAccessUrl && (
            <LoadingButton
              loading={isLoadingButton}
              fullWidth
              variant='contained'
              onClick={onClickGetLink}
            >
              получить ссылку
            </LoadingButton>
          )}
        </Stack>

        {publicAccessUrl && (
          <>
            <Divider />

            <Stack spacing={1} sx={{ p: 3, backgroundColor: '#F5F7F9' }}>
              <Typography fontSize='18px' lineHeight='24px' fontWeight={500}>
                Публичная ссылка
              </Typography>

              <Typography
                component='a'
                href={publicAccessUrl}
                target='_blank'
                sx={{ textDecoration: 'none', wordBreak: 'break-word' }}
                color='primary'
                fontSize='14px'
                fontWeight={500}
              >
                {publicAccessUrl}
              </Typography>

              <Box
                onClick={onCopyHref}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  padding: '4px 5px',
                  ml: '4px',
                  cursor: 'pointer',
                }}
              >
                <ContentCopyRoundedIcon
                  sx={{ width: '18px', height: '18px' }}
                  color='primary'
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    letterSpacing: '0.46px',
                  }}
                  color='primary'
                >
                  Скопировать ссылку
                </Typography>
              </Box>

              {isMobileScreen && (
                <Box
                  onClick={onClickDeleteLink}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '4px 5px',
                    ml: '4px',
                    cursor: 'pointer',
                  }}
                >
                  <DeleteOutlineIcon
                    sx={{ width: '18px', height: '18px' }}
                    color='error'
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      letterSpacing: '0.46px',
                    }}
                    color='error'
                  >
                    удалить ссылку
                  </Typography>
                </Box>
              )}
            </Stack>
          </>
        )}

        <Divider />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: {
              xs: 'unset',
              sm: !publicAccessUrl ? 'flex-end' : 'space-between',
            },
            gap: { xs: 2, sm: 0 },
            marginTop: 'auto',
            p: { xs: 2, sm: 3 },
          }}
        >
          {publicAccessUrl && !isMobileScreen && (
            <LoadingButton
              loading={isLoadingButton}
              sx={{ width: { xs: '100%', sm: 'auto' }, p: '12px 11px' }}
              variant='text'
              color='error'
              onClick={onClickDeleteLink}
            >
              удалить ссылку
            </LoadingButton>
          )}

          <LoadingButton
            loading={isLoadingButton}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            variant='contained'
            onClick={onClose}
          >
            готово
          </LoadingButton>
        </Box>
      </DialogContent>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
        message='Ссылка на занятие скопирована в буфер обмена'
      />
    </Dialog>
  )
}
