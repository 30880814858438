/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ClassTypeEnum {
    FINISHED_SCHOOL = 'finished_school',
    _11 = '11',
    _10 = '10',
    _9 = '9',
    _8 = '8',
    _7 = '7',
    _6 = '6',
    _5 = '5',
    _4 = '4',
    _3 = '3',
    _2 = '2',
    _1 = '1',
    PRESCHOOL = 'preschool',
}
