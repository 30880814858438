import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'
import FileUploader from '../wrappers/FileUploader'
import { FileInfoDTO, FilesService } from '../../generated/api'
import Dialog from '../custom/Dialog/Dialog'
import { fileTypes } from '../../helpers/constants/fileTypes'

interface FileUploaderDialogProps {
  open: boolean
  onClose: () => void
  onInsert: (file: FileInfoDTO) => void
}

export default function FileUploaderDialog(props: FileUploaderDialogProps) {
  const { open, onClose, onInsert } = props

  const [fileId, setFileId] = React.useState<number | null>(null)
  const onFileChange = useCallback(
    (value: number[]) => {
      setFileId(value.length ? value[0] : null)
    },
    [setFileId]
  )
  const onInsertClick = useCallback(() => {
    if (!fileId) {
      return
    }
    FilesService.getAppApiFilesuploaderUploaderGetbyids({
      idsArray: [fileId],
    }).then((response) => {
      const file = response[0]
      onInsert(file)
    })
  }, [fileId, onInsert])
  return (
    <Dialog fullWidth open={open} maxWidth='xs'>
      <DialogContent>
        <Typography
          fontWeight={500}
          variant='h1'
          sx={{ fontSize: { xs: '24px', sm: '32px' }, p: { xs: 2, sm: 3 } }}
        >
          Загрузка файлов
        </Typography>

        <FileUploader
          value={fileId ? [fileId] : []}
          types={fileTypes}
          onChange={onFileChange}
        />
        <Typography
          variant='caption'
          display='block'
          sx={{ p: { xs: '0 16px 16px 16px', sm: '0 24px 24px 24px' } }}
        >
          Загружать разрешено только аудио (.wav, .mp3), текстовые (.docx, .doc, .txt,
          odt, .pdf), табличные (.xlsx, .xls, .csv) файлы и архивы (.zip, .rar)
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignSelf: { xs: 'unset', sm: 'end' },
          gap: { xs: 2, sm: 0 },
          flexDirection: { xs: 'column', sm: 'row' },
          p: { xs: 2, sm: 3 },
          '& > :not(:first-of-type)': {
            ml: 0,
          },
        }}
      >
        <Button
          color='error'
          onClick={onClose}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          Закрыть
        </Button>
        <Button
          disabled={!fileId}
          onClick={onInsertClick}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
          variant='contained'
        >
          Вставить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
