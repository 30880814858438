export const fileTypes = [
  'audio/*',
  'application/pdf',
  'text/plain',
  'application/msword',
  'text/csv',
  'application/vnd.ms-excel',
  'application/zip',
  'application/x-rar-compressed',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.text',
]
