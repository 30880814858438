import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EmployeeService } from '../../../generated/api'
import CheckerAutocompleteOption from './components/CheckerAutocompleteOption'
import CheckerAutocompleteInput from './components/CheckerAutocompleteInput'
import { CheckerAutocompleteProps, CheckerAutocompleteState } from './types'
import { CHECKER_ROLE, TEACHER_ROLE } from './constants'
import { Autocomplete, TextField } from '@mui/material'
import Paper from '@mui/material/Paper'

export default function CheckerAutocomplete(props: CheckerAutocompleteProps) {
  const {
    value,
    onChange,
    error,
    subjectId,
    label = 'Проверяющий',
    marginLeft = '0',
    disabled,
    fixedOptionName,
    initialFixedOption,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [checkers, setCheckers] = useState<CheckerAutocompleteState[]>([])

  const fixedOption = useMemo(() => {
    if (typeof initialFixedOption !== 'undefined') return initialFixedOption

    return {
      id: 'allowed',
      name: fixedOptionName || 'Мои',
    }
  }, [initialFixedOption])

  const selectedValue = useMemo(() => {
    if (checkers) {
      return checkers.find((el) => el.id === value)
    }
  }, [value, checkers])

  const loadEmployees = useCallback(() => {
    setIsLoading(true)

    return EmployeeService.getAppApiUserEmployeecrudGetlistforselect({
      subjectId: subjectId ? String(subjectId) : null,
      roleIdsArray: [CHECKER_ROLE, TEACHER_ROLE],
    })
      .then((response) => {
        setCheckers(() => {
          const arr: CheckerAutocompleteState[] = []

          if (fixedOption) {
            arr.push(fixedOption)
          }

          response.forEach(({ id, name }) => {
            if (id && name) {
              arr.push({
                id: String(id),
                name: name,
              })
            }
          })

          return arr
        })
      })
      .finally(() => setIsLoading(false))
  }, [subjectId])

  useEffect(() => {
    loadEmployees()
  }, [subjectId])

  if (isLoading) {
    return <TextField fullWidth label={label} size='small' disabled />
  }

  return (
    <Autocomplete
      disableClearable={!value}
      loading={isLoading}
      disabled={disabled}
      options={checkers as any}
      renderOption={(
        props: Record<string, any>,
        option: { id: string; name: string }
      ) => {
        return (
          <CheckerAutocompleteOption options={props} id={option.id} name={option.name} />
        )
      }}
      getOptionLabel={(option: CheckerAutocompleteState) => option.name}
      renderInput={(params: any) => (
        <CheckerAutocompleteInput
          value={value}
          label={label}
          error={error}
          marginLeft={marginLeft}
          options={props}
          params={params}
        />
      )}
      PaperComponent={(props) => {
        return (
          <Paper
            sx={{ p: 0, minWidth: isLoading ? 'unset' : { xs: '100%', sm: '500px' } }}
            elevation={1}
          >
            {props.children}
          </Paper>
        )
      }}
      onChange={(e, option) => {
        onChange(e, option?.id)
      }}
      value={selectedValue}
      defaultValue={selectedValue}
    />
  )
}
