/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AbstractTaskSaveRequest } from '../models/AbstractTaskSaveRequest';
import type { ClassTypeEnum } from '../models/ClassTypeEnum';
import type { EmployeeTaskDTO } from '../models/EmployeeTaskDTO';
import type { GroupGetListResponse } from '../models/GroupGetListResponse';
import type { TaskGetListResponse } from '../models/TaskGetListResponse';
import type { TaskStatsResponse } from '../models/TaskStatsResponse';
import type { TaskStatusEnum } from '../models/TaskStatusEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TasksService {
    /**
     * Получение списка задач.
     * @returns TaskGetListResponse Список задач
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcrudGetlist({
        query,
        limit,
        offset,
        subjectIdsArray,
        topicIdsArray,
        examStructTaskIdsArray,
        isPublished,
        examTypeIdsArray,
        id,
        idsArray,
        excludeIdsArray,
        classesArray,
        statusesArray,
        taskSourceIdsArray,
        typesArray,
    }: {
        query?: string | null,
        limit?: number,
        offset?: number,
        subjectIdsArray?: Array<number>,
        topicIdsArray?: Array<number> | null,
        examStructTaskIdsArray?: Array<number> | null,
        isPublished?: boolean | null,
        examTypeIdsArray?: Array<number> | null,
        id?: number | null,
        idsArray?: Array<number> | null,
        excludeIdsArray?: Array<number>,
        classesArray?: Array<ClassTypeEnum> | null,
        statusesArray?: Array<TaskStatusEnum> | null,
        taskSourceIdsArray?: Array<number> | null,
        typesArray?: Array<string> | null,
    }): CancelablePromise<TaskGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks',
            query: {
                'query': query,
                'limit': limit,
                'offset': offset,
                'subjectIds[]': subjectIdsArray,
                'topicIds[]': topicIdsArray,
                'examStructTaskIds[]': examStructTaskIdsArray,
                'isPublished': isPublished,
                'examTypeIds[]': examTypeIdsArray,
                'id': id,
                'ids[]': idsArray,
                'excludeIds[]': excludeIdsArray,
                'classes[]': classesArray,
                'statuses[]': statusesArray,
                'taskSourceIds[]': taskSourceIdsArray,
                'types[]': typesArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание задачи
     * @returns EmployeeTaskDTO Созданная задача
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcrudCreate({
        requestBody,
    }: {
        /**
         * Задача
         */
        requestBody?: AbstractTaskSaveRequest,
    }): CancelablePromise<EmployeeTaskDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tasks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
    /**
     * Получение задачи по идентификатору.
     * @returns EmployeeTaskDTO Задача
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
    }): CancelablePromise<EmployeeTaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найдена`,
            },
        });
    }
    /**
     * Удаление задачи.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationtaskTaskcrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tasks/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление Task.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationtaskTaskcrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
        /**
         * Данные для обновления
         */
        requestBody?: AbstractTaskSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/tasks/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задач с таким идентификатором не найдена`,
            },
        });
    }
    /**
     * Отправка в архив
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcrudActionarchive({
        id,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tasks/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найдена`,
            },
        });
    }
    /**
     * Публикация
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcrudActionpublish({
        id,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tasks/{id}/publish',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найдена`,
            },
        });
    }
    /**
     * Получение списка групп задач.
     * @returns GroupGetListResponse Список групп задач
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcrudGetgrouplist({
        subjectIdsArray,
        limit,
        offset,
        query,
    }: {
        subjectIdsArray?: Array<number>,
        limit?: number,
        offset?: number,
        query?: string | null,
    }): CancelablePromise<GroupGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks/groups',
            query: {
                'subjectIds[]': subjectIdsArray,
                'limit': limit,
                'offset': offset,
                'query': query,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение статистики по задачам
     * @returns TaskStatsResponse Статистика по задачам
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcrudGetstats({
        idsArray,
    }: {
        idsArray?: Array<number> | null,
    }): CancelablePromise<TaskStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks/stats',
            query: {
                'ids[]': idsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
}
