import * as React from 'react'
import { redirect } from 'react-router-dom'
import TaskCheckingResultTasksList from './components/pages/testChecking/TaskCheckingResultTasksList'
import TestTasksView from './components/pages/tests/TestTasksView'
const Tasks = React.lazy(() => import('./components/pages/tasks/Tasks'))
const TaskEdit = React.lazy(() => import('./components/pages/taskEdit/TaskEdit'))
const Lessons = React.lazy(() => import('./components/pages/lessons/LessonsList'))
const Tests = React.lazy(() => import('./components/pages/tests/Tests'))
const TestsTasks = React.lazy(() => import('./components/pages/tests/TestTasksList'))
const TestTaskEdit = React.lazy(() => import('./components/pages/tests/TestTaskEdit'))
const TaskSelect = React.lazy(() => import('./components/pages/tests/TaskSelect'))
const TestTasksList = React.lazy(() => import('./components/pages/tests/TestTasksList'))
const Sources = React.lazy(() => import('./components/pages/sources/SourcesList'))
const LessonsSubThemes = React.lazy(
  () => import('./components/pages/lessons/LessonsSubTopicsList')
)
const LessonSubThemeTasksList = React.lazy(
  () => import('./components/pages/lessons/LessonSubTopicTasksList')
)
const Programs = React.lazy(() => import('./components/pages/program/ProgramList'))
const ProgramCalendar = React.lazy(
  () => import('./components/pages/program/ProgramCalendar')
)
const Students = React.lazy(() => import('./components/pages/students/StudentsList'))
const Subjects = React.lazy(() => import('./components/pages/subjects/SubjectsList'))
const ExamStructTasksList = React.lazy(
  () => import('./components/pages/examStructTasks/ExamStructTasksList')
)
const Users = React.lazy(() => import('./components/pages/users/UsersList'))
const InWorkPage = React.lazy(() => import('./components/pages/InWorkPage'))
const Topics = React.lazy(() => import('./components/pages/topics/TopicsTree'))
const Webinars = React.lazy(() => import('./components/pages/webinars/WebinarsList'))
const StreamsEducation = React.lazy(
  () => import('./components/pages/streamEducation/StreamEducationList')
)
const Onboarding = React.lazy(() => import('./components/pages/onboarding/Onboarding'))
const Settings = React.lazy(() => import('./components/pages/settings/Settings'))

const TestCheckingResultsNew = React.lazy(
  () => import('./components/pages/testChecking/TestCheckingResultsList')
)
const TestCheckingResultTasksListNew = React.lazy(
  () => import('./components/pages/testChecking/TestCheckingResultTasksList')
)
const TaskCheckingResultsListNew = React.lazy(
  () => import('./components/pages/testChecking/TaskCheckingResultsList')
)

export default [
  { path: '/', loader: () => redirect('/webinars') },
  { path: 'tasks', element: <Tasks />, name: 'База заданий' },
  { path: 'tasks/create/:subjectId', element: <TaskEdit />, name: 'Создание заданий' },
  { path: 'tasks/edit', element: <TaskEdit />, name: 'Редактирование задания' },
  { path: 'lessons', element: <Lessons />, name: 'Конструктор уроков' },
  {
    path: 'lessons/:lessonId/sub-topics',
    element: <LessonsSubThemes />,
    name: 'Главы урока',
    parentPath: 'lessons',
  },
  {
    path: 'lessons/:lessonId/sub-topics/:subTopicId/tasks',
    element: <LessonSubThemeTasksList />,
    name: 'Упражнения к главе урока',
    parentPath: 'lessons/:lessonId/sub-topics',
  },
  { path: 'programs', element: <Programs />, name: 'Программы' },
  {
    path: 'programs/:programId/calendar',
    element: <ProgramCalendar />,
    name: 'Календарь',
    parentPath: 'programs',
  },
  { path: 'students', element: <Students />, name: 'Ученики' },
  { path: 'tests', element: <Tests />, name: 'Домашки и тесты' },
  {
    path: 'tests/:testId/tasks',
    element: <TestsTasks />,
    name: 'Задания теста',
    parentPath: 'tests',
  },
  {
    path: 'tests/:testId/tasks/:taskId',
    element: <TestTaskEdit />,
    name: 'Редактирование задания у теста',
    parentPath: 'tests/:testId/tasks',
  },
  {
    path: 'tests/:testId/tasks/select',
    element: <TaskSelect />,
    name: 'Добавление задания из банка',
    parentPath: 'tests/:testId/tasks',
  },
  {
    path: 'tests/:testId/tasks/create',
    element: <TestTaskEdit />,
    name: 'Создание задания у теста',
    parentPath: 'tests/:testId/tasks',
  },
  {
    path: 'tests/:testId/tasks',
    element: <TestTasksList />,
    name: 'Задания теста',
    parentPath: 'tests',
  },
  {
    path: 'tests/:testId/view',
    element: <TestTasksView />,
    name: 'Информация задания теста',
    parentPath: 'tests',
  },
  { path: 'subjects', element: <Subjects />, name: 'Предметы' },
  { path: 'topics', element: <Topics />, name: 'Темы' },
  {
    path: 'exam-struct',
    element: <ExamStructTasksList />,
    name: 'Структуры экзаменационных задач',
  },
  { path: 'task-sources', element: <Sources />, name: 'Источники заданий' },
  { path: 'users', element: <Users />, name: 'Пользователи' },
  { path: 'webinars', element: <Webinars />, name: 'Вебинары new' },
  { path: 'streams-education', element: <StreamsEducation />, name: 'Потоки обучения' },
  {
    path: 'onboarding',
    element: <Onboarding />,
    name: 'Регистрация преподавателя',
    withoutLayout: true,
  },
  { path: 'settings', element: <Settings />, name: 'Настройки' },
  { path: 'checking/tests', element: <TestCheckingResultsNew />, name: 'Проверка работ' },
  {
    path: 'checking/tests/:resultId',
    element: <TestCheckingResultTasksListNew />,
    name: 'Список пройденных заданий',
    parentPath: 'test-checking-results',
  },
  {
    path: 'checking/tests/:resultId/view',
    element: <TestCheckingResultTasksListNew />,
    name: 'Просмотр списка пройденных заданий',
    parentPath: 'test-checking-results',
  },
  {
    path: 'checking/tasks',
    element: <TaskCheckingResultsListNew />,
    name: 'Проверка работ',
  },
  {
    path: 'checking/tasks/:resultId',
    element: <TaskCheckingResultTasksList />,
    name: 'Проверка задания',
  },
]
