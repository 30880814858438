import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import DialogContent from '@mui/material/DialogContent'
import { useFormik } from 'formik'
import {
  ApiError,
  ImportUserRequest,
  RegisterStudentRequest,
  StudentService,
  UsersImportService,
} from '../../../generated/api'
import { useCallback, useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Divider, Stack } from '@mui/material'
import formErrorHandle from '../../../helpers/FormErrorHandler'
import SubjectAutocomplete from '../../autocomplete/SubjectAutocomplete'
import ExamTypeAutocomplete from '../../autocomplete/ExamTypeAutocomplete'
import EmployeeAutocomplete, {
  CURATOR_ROLE,
} from '../../autocomplete/EmployeeAutocomplete'
import Typography from '@mui/material/Typography'
import ClassAutocomplete from '../../autocomplete/ClassAutocomplete'
import EducationAccessLevelSelect from '../../autocomplete/EducationAccessLevelSelect'
import Dialog from '../../custom/Dialog/Dialog'

export default function StudentEditDialog({
  model,
  id,
  onClose,
  onSave,
  isSchoolTeacher,
}: {
  model: RegisterStudentRequest
  id: number | null
  onClose: () => void
  onSave: () => void
  isSchoolTeacher: boolean
}) {
  const title = useMemo(() => {
    return id ? 'Редактирование пользователя' : 'Добавление пользователя'
  }, [id])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isImportMode, setIsImportMode] = useState<boolean>(id === null)

  const formik = useFormik<RegisterStudentRequest>({
    initialValues: model,
    validate: (values) => {
      if (isSchoolTeacher) {
        const errors: Record<string, string> = {}

        if (!values.class) {
          errors.class = 'Выберите класс'
        }
        if (!values.subjectIds || values.subjectIds.length === 0) {
          errors.subjectIds = 'Добавьте предмет'
        }
        if (!values.interestedExamTypeId) {
          errors.interestedExamTypeId = 'Добавьте экзамен'
        }
        return errors
      }
    },
    onSubmit: (values: RegisterStudentRequest) => {
      setIsLoading(true)
      const callback = id
        ? StudentService.patchAppApiUserStudentcrudActionupdate({
            id: id,
            requestBody: values,
          })
        : StudentService.postAppApiUserStudentcrudActionregister({
            requestBody: values,
          })
      callback
        .finally(() => setIsLoading(false))
        .then(() => {
          onSave()
        })
        .catch((err: ApiError) => {
          formErrorHandle(err, formik)
        })
    },
  })
  const importFormik = useFormik({
    initialValues: {
      phone: '',
    },
    onSubmit: (values: ImportUserRequest) => {
      setIsLoading(true)
      const callback = UsersImportService.postAppApiUserImportActionimportstudent({
        requestBody: values,
      })
      callback
        .finally(() => setIsLoading(false))
        .then((employee) => {
          onSave()
        })
        .catch((err: ApiError) => {
          if (err.status === 404) {
            setIsImportMode(false)
            formik.setFieldValue('phone', values.phone)
          } else {
            formErrorHandle(err, formik)
          }
        })
    },
  })

  const onSubmitClick = useCallback(() => {
    if (isImportMode) {
      importFormik.handleSubmit()
    } else {
      formik.handleSubmit()
    }
  }, [formik])

  const onSubjectsChange = useCallback((e: any, value: any) => {
    formik.setFieldValue('subjectIds', value)
  }, [])
  const onCuratorChange = useCallback((e: any, value: any) => {
    formik.setFieldValue('curatorId', value)
  }, [])
  const onInterestedExamChange = useCallback((e: any, value: any) => {
    formik.setFieldValue('interestedExamTypeId', value)
  }, [])

  const onClassTaskChange = useCallback(
    (event: any, value: any) => {
      formik.setFieldValue('class', value)
    },
    [formik]
  )

  const onEducationAccessLevelChange = useCallback(
    (event: any, value: any) => {
      formik.setFieldValue('educationAccessLevel', value ? value : null)
    },
    [formik]
  )

  return (
    <Dialog fullWidth maxWidth={'sm'} open={true} onClose={onClose}>
      <Typography variant='h5' fontWeight='500' sx={{ p: { xs: 2, sm: 3 } }}>
        {title}
      </Typography>

      <Divider />

      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack spacing={2} sx={{ p: { xs: 2, sm: 3 } }}>
          {isImportMode ? (
            <TextField
              autoFocus
              fullWidth
              margin='dense'
              id='phone'
              label='Телефон'
              variant='outlined'
              value={importFormik.values.phone}
              onChange={importFormik.handleChange}
              error={!!importFormik.errors.phone}
              helperText={importFormik.errors.phone}
              required
            />
          ) : (
            <>
              <TextField
                autoFocus
                fullWidth
                margin='dense'
                id='phone'
                label='Телефон'
                variant='outlined'
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={!!formik.errors.phone}
                helperText={formik.errors.phone}
                disabled={id !== null}
                required
              />
              <TextField
                name='name'
                fullWidth
                margin='dense'
                label='Имя'
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                required
              />
              {isSchoolTeacher && (
                <EmployeeAutocomplete
                  multiple={false}
                  label='Куратор'
                  value={formik.values.curatorId}
                  error={formik.errors.curatorId}
                  onChange={onCuratorChange}
                  roleIds={[CURATOR_ROLE]}
                />
              )}

              <TextField
                name='messengerLink'
                fullWidth
                margin='dense'
                label='Ссылка на мессенджер'
                onChange={formik.handleChange}
                value={formik.values.messengerLink}
                error={!!formik.errors.messengerLink}
                helperText={formik.errors.messengerLink}
              />
              {isSchoolTeacher && (
                <>
                  <SubjectAutocomplete
                    multiple
                    fullWidth
                    error={formik.touched.subjectIds && formik.errors.subjectIds}
                    onChange={onSubjectsChange}
                    value={formik.values.subjectIds}
                    required={isSchoolTeacher}
                  />
                  <ExamTypeAutocomplete
                    multiple={false}
                    fullWidth
                    error={formik.errors.interestedExamTypeId}
                    onChange={onInterestedExamChange}
                    value={formik.values.interestedExamTypeId}
                    required={isSchoolTeacher}
                  />
                </>
              )}

              <ClassAutocomplete
                size='medium'
                value={formik.values.class ?? null}
                onChange={onClassTaskChange}
                multiple={false}
                error={formik.touched.class && formik.errors.class}
                required={isSchoolTeacher}
              />

              {isSchoolTeacher && (
                <>
                  <EducationAccessLevelSelect
                    fullWidth
                    multiple={false}
                    value={formik.values.educationAccessLevel}
                    onChange={onEducationAccessLevelChange}
                  />
                  <TextField
                    name='parentName'
                    fullWidth
                    margin='dense'
                    label='Имя родителя'
                    onChange={formik.handleChange}
                    value={formik.values.parentName}
                    error={!!formik.errors.parentName}
                    helperText={formik.errors.parentName}
                  />
                  <TextField
                    name='parentPhone'
                    fullWidth
                    margin='dense'
                    label='Телефон родителя'
                    onChange={formik.handleChange}
                    value={formik.values.parentPhone}
                    error={!!formik.errors.parentPhone}
                    helperText={formik.errors.parentPhone}
                  />
                  <TextField
                    name='parentEmail'
                    fullWidth
                    margin='dense'
                    label='Email родителя'
                    onChange={formik.handleChange}
                    value={formik.values.parentEmail}
                    error={!!formik.errors.parentEmail}
                    helperText={formik.errors.parentEmail}
                  />
                </>
              )}
            </>
          )}
        </Stack>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            alignSelf: { xs: 'unset', sm: 'flex-end' },
            width: { xs: '100%', sm: 'auto' },
            marginTop: 'auto',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 0 },
            p: { xs: 2, sm: 3 },
            '& > :not(:first-of-type)': {
              ml: 0,
            },
          }}
        >
          <Button
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            color='error'
            disabled={isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>
          <LoadingButton
            variant='contained'
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            loading={isLoading}
            onClick={onSubmitClick}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
