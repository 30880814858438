import TextField from "@mui/material/TextField";
import ApiAutocomplete from "../extended/ApiAutocomplete";
import * as React from "react";
import {SyntheticEvent, useCallback, useState} from "react";
import {SubjectsService, SubjectDTO} from "../../generated/api";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";

interface SubjectAutocompleteProps extends Omit<TextFieldProps, 'onChange' | 'value' | 'error'> {
    value: any,
    multiple: boolean,
    onChange: (event: SyntheticEvent, value: any) => void,
    error?: React.ReactNode,
    label?: string,
    variant?: TextFieldVariants,
    autoSelectIfSingle?: boolean,
    withoutRestriction?: boolean
    subjects?: SubjectDTO[] | null
}

export default function SubjectAutocomplete({
  value,
  multiple,
  onChange,
  error,
  autoSelectIfSingle,
  withoutRestriction,
  label = 'Предмет',
  disabled,
  subjects,
  ...props
}: SubjectAutocompleteProps) {
  const [isLoading,setIsLoading] = useState(false)
  const loadSubjects = useCallback(({query, ids}: {query?: string, ids?: number[]}) => {
    setIsLoading(true)
    if (subjects) {
        return new Promise<Array<SubjectDTO>>((resolve) => resolve(subjects)).finally(() => setIsLoading(false))
    }

    return SubjectsService.getAppApiReferencesSubjectcrudGetlist({
      idsArray: ids,
      query: query,
      withoutRestriction: withoutRestriction,
      limit: 100,
      offset: 0
    }).then((response) => {
        return response.items || []
    }).finally(() => setIsLoading(false))
  }, [withoutRestriction, subjects])

  return (
      <ApiAutocomplete
          disabled={disabled}
          autoSelectIfSingle={autoSelectIfSingle}
          multiple={multiple}
          load={loadSubjects}
          loading={isLoading}
          options={[]}
          labelField={'name'}
          valueField={'id'}
          renderInput={(params) => {
              const s = subjects?.find((subject) => subject.id === value)

              return (
                  <TextField
                      {...params}
                      inputProps={{...params.inputProps, value: s?.name || params?.inputProps?.value}}
                      fullWidth
                      label={label}
                      error={!!error}
                      helperText={error}
                      {...props}
                  />
              )
          }}
          onChange={onChange}
          formValue={value}
          defaultValue={value}
      />
  )
}

