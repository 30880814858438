import { FilePond, registerPlugin } from 'react-filepond'
import * as React from 'react'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import { FilePondFile, FilePondInitialFile } from 'filepond'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as url from 'url'
// Import the plugin code
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
// Import the plugin styles
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
// @ts-ignore
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'
import getToken from '../../helpers/getToken'
import { FilesService } from '../../generated/api'
import { logout } from '../../helpers/logout'

// Register the plugins
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateType,
  FilePondPluginMediaPreview
)

export default function FileUploader({
  value,
  types,
  onChange,
  multiplyValue = false,
}: {
  value: number[]
  types: string[]
  onChange?: (fileIds: number[]) => void
  multiplyValue?: boolean
}) {
  const token = getToken()
  const filePondRef = useRef<any>(null)
  const [files, setFiles] = useState<any[]>([])
  useEffect(() => {
    const newValues = value.filter(
      (serverId) => !files.find((file) => file.serverId === serverId)
    )
    if (!newValues.length) {
      return
    }
    FilesService.getAppApiFilesuploaderUploaderGetbyids({
      idsArray: newValues,
    }).then((response) => {
      setFiles(
        files.concat(
          response.map((file) => ({
            source: file.id,
            options: {
              type: 'local',
              file: {
                name: file.fileName,
                size: file.size,
                type: file.mimeType,
              },
              metadata: {
                poster: file.url,
              },
            },
          }))
        )
      )
    })
  }, [value])

  const handleFileChange = useCallback(
    (files: FilePondFile[]) => {
      setFiles(files)
      if (onChange) {
        onChange(
          files
            .map((file) => file.serverId)
            .filter((id) => id)
            .map((id) => parseInt(id))
        )
      }
    },
    [setFiles, onChange]
  )

  const handleProcessFiles = useCallback(() => {
    if (onChange) {
      onChange(
        filePondRef.current
          .getFiles()
          .map((file: any) => file.serverId)
          .filter((id: any) => id)
          .map((id: any) => parseInt(id))
      )
    }
  }, [filePondRef, onChange])

  return (
    <FilePond
      ref={(ref) => (filePondRef.current = ref)}
      files={files}
      onupdatefiles={handleFileChange}
      onprocessfiles={handleProcessFiles}
      allowMultiple={multiplyValue}
      name='file'
      labelIdle='Перетащите ваши файлы или <span class="filepond--label-action">Выберите на диске...</span>'
      server={{
        url: process.env.REACT_APP_API_URL + '/admin/files',
        process: {
          url: '',
          method: 'POST',
          withCredentials: false,
          headers: {
            Authorization: 'Bearer ' + token,
          },
          onload: (response) => JSON.parse(response).id,
          onerror: (response) => console.log(response),
        },
        revert: {
          url: '',
          method: 'DELETE',
          withCredentials: false,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      }}
      stylePanelLayout='compact'
      acceptedFileTypes={types}
      filePosterMaxHeight={250}
    />
  )
}
