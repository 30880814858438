import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  List,
  ListItem,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from 'formik'
import DialogActions from '@mui/material/DialogActions'
import {
  CommonTestsService,
  IndividualTestRecipientDTO,
  IndividualTestResultsService,
  RightsEnum,
  SendIndividualTestRequest,
  StudentService,
} from '../../generated/api'
import globalTheme from '../../styles/globalTheme'
import AddIcon from '@mui/icons-material/Add'
import StudentAutocomplete from '../autocomplete/StudentAutocomplete'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import formErrorHandle from '../../helpers/FormErrorHandler'
import AddNewStudent from './AddNewStudent'
import Dialog from './Dialog/Dialog'
import getUtcDateTime from '../../helpers/getUtcDateTime'
import CheckerAutocomplete from '../autocomplete/CheckerAutocomplete'
import { ProfileContext } from '../../App'

interface TestAssignModalProps {
  title: string
  isOpen: boolean
  testId: number
  onSave: (id?: number) => void
  onClose: () => void
  /** Когда репетитор выдаёт домашку с общей базы
   * true - сначала копирует к себе а потом выдаёт ученику
   * false - сразу выдаёт ученику
   */
  isCopyTest?: boolean
  subjectId?: string
}

export default function TestAssignModal(props: TestAssignModalProps) {
  const { testId, title, isOpen, onClose, onSave, isCopyTest = false, subjectId } = props

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik<SendIndividualTestRequest>({
    initialValues: {
      testId: testId,
      recipients: [
        {
          userId: null,
          deadline: null,
        },
      ],
      checkerId: null,
    },
    onSubmit: (values: any, { setSubmitting }) => {
      setSubmitting(true)

      const callback = isCopyTest
        ? CommonTestsService.postAppApiEducationCommontestsCopy({ id: testId })
        : IndividualTestResultsService.postAppApiEducationIndividualtestActionsend({
            requestBody: values,
          })

      callback
        .then((res) => {
          if (isCopyTest && res) {
            IndividualTestResultsService.postAppApiEducationIndividualtestActionsend({
              requestBody: { ...values, testId: res?.id || 0 },
            })
              .then(() => {
                onSave(res?.id || 0)
              })
              .catch((err) => {
                formErrorHandle(err, formik)
              })
          }

          if (!isCopyTest) {
            onSave()
          }
        })
        .catch((err) => {
          formErrorHandle(err, formik)
        })
        .finally(() => setSubmitting(false))
    },
  })

  const profile = useContext(ProfileContext)

  const [isHaveStudents, setIsHaveStudents] = useState(false)
  const [isOpenAddNewStudent, setIsOpenAddNewStudent] = useState(false)

  const onOpenAddNewStudent = useCallback(() => {
    setIsOpenAddNewStudent(true)
  }, [])

  const onCloseAddNewStudent = useCallback(() => {
    setIsOpenAddNewStudent(false)
  }, [])

  const onChangeStudents = useCallback(
    (index: number, student: any) => {
      formik.setFieldValue(`recipients[${index}].userId`, student)
    },
    [formik]
  )

  const onChangeCheckerId = useCallback(
    (checkerId: any) => {
      formik.setFieldValue(`checkerId`, Number(checkerId))
    },
    [formik]
  )

  const onAddNewStudent = useCallback(
    (newStudent: any) => {
      if (!isHaveStudents) {
        setIsHaveStudents(true)
      }
      const newRecipients = [
        //@ts-ignore
        ...formik.values.recipients.filter(
          (recipient: IndividualTestRecipientDTO) =>
            recipient.userId !== null && recipient.userId !== newStudent.id
        ),
        { userId: newStudent.id, deadline: null },
      ]
      formik.setFieldValue('recipients', newRecipients)
    },
    [formik, isHaveStudents]
  )

  const onChangeDeadlineStudent = useCallback(
    (index: number, value: Dayjs | null) => {
      if (value && value.isValid()) {
        const newDate = getUtcDateTime(value)
        formik.setFieldValue(`recipients[${index}].deadline`, newDate)
      } else {
        formik.setFieldValue(`recipients[${index}].deadline`, null)
      }
    },
    [formik]
  )

  useEffect(() => {
    setIsLoading(true)
    StudentService.getAppApiUserStudentcrudGetuserslistforselect({})
      .then((res) => setIsHaveStudents(res.length > 1))
      .finally(() => setIsLoading(false))
  }, [])

  const isAllowedCheckerId = useMemo(() => {
    if (profile?.rights) {
      return profile.rights.some((r) => {
        return (
          r === RightsEnum.SUPERADMIN ||
          r === RightsEnum.ADMIN ||
          r === RightsEnum.SELECT_OTHER_TEACHERS_FOR_INDIVIDUAL_TEST
        )
      })
    }

    return false
  }, [profile])

  return (
    <>
      <FormikProvider value={formik}>
        <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={onClose}>
          <DialogContent>
            <Typography fontSize='24px' fontWeight={500} sx={{ p: { xs: 2, sm: 3 } }}>
              {title}
            </Typography>

            <Divider />

            {isAllowedCheckerId && (
              <Box sx={{ p: { xs: 2, sm: 3 } }} style={{ paddingBottom: 0 }}>
                <CheckerAutocomplete
                  value={formik.values.checkerId}
                  onChange={(event, newValue) => onChangeCheckerId(newValue)}
                  initialFixedOption={null}
                  subjectId={subjectId ? String(subjectId) : undefined}
                />
              </Box>
            )}

            {isLoading ? (
              <Box sx={{ p: 3, textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <FieldArray
                name='recipients'
                render={({ push, remove }: any) => (
                  <Box sx={{ p: { xs: 2, sm: 3 } }}>
                    {isHaveStudents && (
                      <List sx={{ p: 0 }}>
                        {formik.values.recipients?.map((student, index) => {
                          return (
                            <ListItem
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 1,
                                flexGrow: 1,
                                p: 0,
                                mb: 2,
                                width: '100%',
                              }}
                            >
                              <FormControl
                                sx={{ flexGrow: 1, width: '100%', display: 'block' }}
                              >
                                <StudentAutocomplete
                                  multiple={false}
                                  size='medium'
                                  fullWidth
                                  value={formik.values.recipients ? student.userId : null}
                                  onChange={(event, newValue) =>
                                    onChangeStudents(index, newValue)
                                  }
                                  marginLeft='5px'
                                  isAllowedAddNewStudent={true}
                                  disableClearable={!!student.userId}
                                  disabled={formik.isSubmitting}
                                  onCloseAddNewStudent={onCloseAddNewStudent}
                                  onOpenAddNewStudent={onOpenAddNewStudent}
                                  isOpenAddNewStudent={isOpenAddNewStudent}
                                  onAddNewStudent={onAddNewStudent}
                                />

                                <FormHelperText error>
                                  {formik.errors &&
                                    Array.isArray(formik.errors.recipients) &&
                                    'userId' in formik.errors.recipients[index] &&
                                    formik.errors.recipients[index].userId}
                                </FormHelperText>
                              </FormControl>

                              <FormControl sx={{ width: { xs: '100%', sm: 'auto' } }}>
                                <DatePicker
                                  minDate={dayjs()}
                                  label='Дедлайн'
                                  disabled={formik.isSubmitting}
                                  value={
                                    student.deadline ? dayjs(student.deadline) : null
                                  }
                                  onChange={(value: Dayjs | null) =>
                                    onChangeDeadlineStudent(index, value)
                                  }
                                  sx={{
                                    '& .Mui-error': {
                                      color: 'rgba(0, 0, 0, 0.6)',
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(0, 0, 0, 0.23) !important',
                                      },
                                    },
                                  }}
                                />
                                <FormHelperText error>
                                  {formik.errors &&
                                    Array.isArray(formik.errors.recipients) &&
                                    'deadline' in formik.errors.recipients[index] &&
                                    formik.errors.recipients[index].deadline}
                                </FormHelperText>
                              </FormControl>

                              {formik.values.recipients &&
                                formik.values.recipients.length > 1 && (
                                  <IconButton
                                    onClick={() => remove(index)}
                                    disabled={formik.isSubmitting}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                )}
                            </ListItem>
                          )
                        })}
                      </List>
                    )}

                    {isHaveStudents ? (
                      <Button
                        fullWidth
                        sx={{
                          backgroundColor: globalTheme.palette.greyBlue150.main,
                          color: '#131920DE',
                          boxShadow: 'unset',
                          '&.MuiButtonBase-root.MuiButton-root:hover': {
                            backgroundColor: '#4a5b6b',
                            boxShadow: 'unset',
                            color: 'white',
                          },
                        }}
                        startIcon={<AddIcon />}
                        disabled={formik.isSubmitting}
                        onClick={() => push({ userId: null, deadline: null })}
                      >
                        Добавить ученика
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        startIcon={<AddIcon />}
                        disabled={formik.isSubmitting}
                        sx={{
                          backgroundColor: globalTheme.palette.greyBlue150.main,
                          color: '#131920DE',
                          boxShadow: 'unset',
                          '&.MuiButtonBase-root.MuiButton-root:hover': {
                            backgroundColor: '#4a5b6b',
                            boxShadow: 'unset',
                            color: 'white',
                          },
                        }}
                        onClick={onOpenAddNewStudent}
                      >
                        пригласить ученика
                      </Button>
                    )}

                    <Box
                      sx={{
                        color: '#FF0829',
                        fontSize: '0.75rem',
                        mt: '3px',
                        ml: '14px',
                      }}
                    >
                      <ErrorMessage name='studentIds' />
                    </Box>
                  </Box>
                )}
              />
            )}
          </DialogContent>

          <Divider />

          <DialogActions
            sx={{
              padding: { xs: '16px', sm: '18px 24px' },
              display: 'flex',
              justifyContent: { xs: 'unset', sm: 'space-between' },
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 2, sm: 0 },
              '& > :not(:first-of-type)': {
                ml: { xs: 0, sm: 1 },
              },
            }}
          >
            <Button
              disabled={formik.isSubmitting}
              onClick={onClose}
              color='error'
              variant='text'
              sx={{ p: '12px 11px', width: { xs: '100%', sm: 'auto' } }}
            >
              отмена
            </Button>

            <Button
              onClick={formik.submitForm}
              variant='contained'
              disabled={formik.isSubmitting}
              sx={{ width: { xs: '100%', sm: '148px' } }}
            >
              выдать
            </Button>
          </DialogActions>
        </Dialog>
      </FormikProvider>

      <AddNewStudent
        isOpenAddNewStudent={isOpenAddNewStudent}
        onClose={onCloseAddNewStudent}
        onSave={onAddNewStudent}
      />
    </>
  )
}
