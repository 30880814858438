import queryString, {ParsedUrl, ParseOptions, StringifyOptions} from "query-string"
import {queryStringBaseOptions} from "./constants";
import {arrayUnique} from "../array";
import difference from 'lodash.difference'

export interface IStringifyUrl {
    (
        url: string,
        params: {
            set?: IStringifyUrlQuery
            include?: IStringifyUrlQuery
            update?: IStringifyUrlQuery
            exclude?: (string | IStringifyUrlQuery)[]
        },
        options?: any,
        fragment?: string
    ): string
}

export interface IStringifyUrlQuery {
    [key: string]: string | string[] | number | number[] | undefined
}

/** Возвращает search params из урла */
export const getSearchParams = (url: string): URLSearchParams => {
    const queries = url.replace(/.*(\?.*)/g, '$1')

    return new URLSearchParams(queries)
}


/** Из query string формирует объект с учетом массивов `foo[]=bar1&foo[]=bar2` */
export const queryStringToObject = (url: string): Record<string, unknown> => {
    const params = getSearchParams(url)
    const obj = {} as Record<string, unknown>

    // eslint-disable-next-line no-restricted-syntax
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key.replace(/\[]$/g, '')] = params.getAll(key)
        } else {
            const value = params.get(key)
            const isValueArr = /\[]$/g.test(key)

            if (value) {
                obj[key.replace(/\[]$/g, '')] = isValueArr ? [value] : value
            }
        }
    }

    return obj
}

/* Исключает из урла определенные параметры. Подробнее: https://github.com/sindresorhus/query-string#excludeurl-keys-options */
export const excludeFromUrl = (
    url: string,
    target: string[] | ((key: string, value: string | boolean | number) => boolean),
    options: ParseOptions & StringifyOptions = {}
): string => {
    // @ts-ignore
    return queryString.exclude(url, target, {
        ...queryStringBaseOptions,
        ...options,
    })
}

/* Исключает из урла параметры, которые можно передавать как только названием, так и со значениями */
export const excludeExtendedFromUrl = (
    url: string,
    exclude: (string | IStringifyUrlQuery)[],
    options: ParseOptions & StringifyOptions = {}
): string => {
    const optionsObject = {
        ...queryStringBaseOptions,
        ...options,
    }
    let tmpUrl = url

    exclude.forEach((excludeParam) => {
        if (typeof excludeParam === 'string') {
            /* Если нужно исключить весь параметр не зависимо от значения */
            tmpUrl = excludeFromUrl(tmpUrl, [excludeParam], optionsObject)
        } else if (excludeParam) {
            /* Если переданы параметры со значениями, то идем по каждому значению */
            Object.keys(excludeParam).forEach((excludeParamName) => {
                const excludeParamValue = excludeParam[excludeParamName]
                const parsedUrl = parseUrl(tmpUrl as string, optionsObject)
                const queryFromUrl = parsedUrl.query
                const queryFromUrlParamValue = queryFromUrl[excludeParamName] || ''
                const isQueryFromUrlParamValueArray = Array.isArray(queryFromUrlParamValue)

                /* Если значение является массивом, то исключаем значения массива из квери параметров */
                if (Array.isArray(excludeParamValue) || isQueryFromUrlParamValueArray) {
                    const excludeParamValueArray = Array.isArray(excludeParamValue)
                        ? excludeParamValue
                        : [excludeParamValue]

                    queryFromUrl[excludeParamName] = Array.isArray(queryFromUrlParamValue)
                        ? difference(
                            queryFromUrlParamValue.map((value) => String(value)),
                            excludeParamValueArray.map((value) => String(value))
                        )
                        : queryFromUrlParamValue

                    tmpUrl = queryString.stringifyUrl(
                        {
                            url: parsedUrl.url,
                            query: queryFromUrl,
                        },
                        optionsObject
                    )
                } else {
                    /* Если значение не массив, то исключаем его из квери параметра */
                    tmpUrl = excludeFromUrl(
                        tmpUrl,
                        (name, value) => {
                            return name === excludeParamName && value === excludeParamValue
                        },
                        optionsObject
                    )
                }
            })
        }
    })

    return tmpUrl
}

export const parseUrl = (
    url: string,
    options: ParseOptions & StringifyOptions = {}
): ParsedUrl => {
    return queryString.parseUrl(url, {
        ...queryStringBaseOptions,
        ...options,
    })
}

export const stringifyUrl: IStringifyUrl = (url, params, options = {}, fragment) => {
    const parsedUrl = parseUrl(url)

    const fragmentValue = (fragment || parsedUrl.fragmentIdentifier) as string
    const fragmentIdentifier = fragmentValue ? { fragmentIdentifier: fragmentValue } : {}

    const optionsObject = {
        ...queryStringBaseOptions,
        ...options,
    }

    const allQuery = params.set || { ...parsedUrl.query }

    if (params.include) {
        const includePrepared = params.include

        Object.keys(includePrepared).forEach((includeKey) => {
            const originValue = allQuery[includeKey] || []
            const originValueArray = Array.isArray(originValue) ? originValue : [originValue]
            const includeValue = includePrepared[includeKey]

            allQuery[includeKey] = Array.isArray(includeValue)
                ? arrayUnique([
                    ...originValueArray.map((value) => String(value)),
                    ...includeValue.map((value) => String(value)),
                ])
                : String(includeValue)
        })
    }

    if (params.update) {
        const { update } = params

        Object.keys(update).forEach((updateKey) => {
            const updateValue = update[updateKey]

            allQuery[updateKey] = Array.isArray(updateValue)
                ? updateValue.map((value) => String(value))
                : updateValue
        })
    }

    let urlWithQueryString = queryString.stringifyUrl(
        {
            url: parsedUrl.url,
            query: {
                ...allQuery,
            },
            ...fragmentIdentifier,
        },
        optionsObject
    )

    if (params.exclude) {
        urlWithQueryString = excludeExtendedFromUrl(
            urlWithQueryString,
            params.exclude,
            optionsObject
        )
    }

    return urlWithQueryString
}