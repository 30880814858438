import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ClassTypeEnum,
  CommonTestsService,
  EducationTestDTO as TestDTO,
  EducationTestService as TestService,
  EducationTestTaskDTO,
  EducationTestTaskService as TestTaskService,
  EducationTestTypeEnum,
  EmployeeTaskDTO,
  TasksService,
  TaskStatsDTO,
} from '../../../generated/api'
import ContentContainer from '../../custom/ContentContainer'
import {
  Box,
  Button,
  Chip,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import TaskItem from '../../custom/TaskItem'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import theme from '../../../styles/globalTheme'
import TestAssignModal from '../../custom/TestAssignModal'
import TestGenerateLinkDialog from './components/TestGenerateLinkDialog'
import { ProfileContext } from '../../../App'

const chipStyle = {
  fontSize: '14px',
  lineHeight: '16px',
  backgroundColor: '#F5F7F9',
}

const TestTasksView: React.FC = () => {
  const testId = parseInt(useParams()?.testId || '')
  const navigate = useNavigate()
  const isMountRef = useRef(false)
  const [isTestLoading, setIsTestLoading] = useState(true)
  const [isTasksLoading, setIsTasksLoading] = useState(false)
  const [test, setTest] = useState<TestDTO>()
  const [tasks, setTasks] = useState<EducationTestTaskDTO[]>([])
  const [tasksTotal, setTasksTotal] = useState(0)
  const [tasksStats, setTasksStats] = useState<{ [key: number]: TaskStatsDTO }>({})
  const profile = useContext(ProfileContext)
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  // Открывает модалку для выдаче теста ученику
  const [isOpenTestAssign, setIsOpenTestAssign] = useState(false)
  // Открытие попапа с генерации ссылки
  const [openGenerateLinkDialog, setOpenGenerateLinkDialog] = useState(false)

  const getClassLabel = (option: ClassTypeEnum) => {
    switch (option) {
      case ClassTypeEnum.PRESCHOOL:
        return 'Дошкольник'
      case ClassTypeEnum.FINISHED_SCHOOL:
        return 'Закончил'
      default:
        return `${option} класс`
    }
  }

  const refreshTest = useCallback(() => {
    setIsTestLoading(true)
    CommonTestsService.getAppApiEducationCommontestsGetbyid({ id: testId })
      .then((response) => {
        setTest(response)
      })
      .catch(() => {
        navigate('/tests')
      })
      .finally(() => setIsTestLoading(false))
  }, [])

  const refreshTestTasks = useCallback(() => {
    setIsTasksLoading(true)
    CommonTestsService.getAppApiEducationCommontestsGettasks({
      testId: String(testId),
      limit: 1000,
    })
      .then((response) => {
        setTasksTotal(response.total || 0)
        setTasks(response.items || [])

        const taskIds = response.items?.map((task) => {
          return task?.taskSelector && 'task' in task?.taskSelector
            ? Number(task.taskSelector?.task?.id)
            : 0
        })

        if (taskIds?.length) {
          TasksService.getAppApiEducationtaskTaskcrudGetstats({ idsArray: taskIds }).then(
            (res) => {
              if (res.stats && Object.keys(res.stats).length > 0) {
                setTasksStats(res.stats)
              }
            }
          )
        }
      })
      .finally(() => {
        setIsTasksLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!isMountRef.current) {
      refreshTest()
    }
    isMountRef.current = true
    refreshTestTasks()
  }, [])

  const onClickCopyTestToMy = useCallback(() => {
    CommonTestsService.postAppApiEducationCommontestsCopy({ id: testId })
      .then(() => {
        navigate('/tests')
      })
      .catch((e) => {
        console.log(e)
      })
  }, [testId])

  // Пока не удалять
  const correctTestLink = useMemo(() => {
    if (test) {
      if (window.location.host.includes('dev.ucheba.space')) {
        return `https://school.dev.ucheba.space/tests/${test?.id}`
      } else if (window.location.host.includes('dev2.ucheba.space')) {
        return `https://school.dev2.ucheba.space/tests/${test?.id}`
      } else if (window.location.host.includes('ucheba.ru')) {
        return `https://student.ucheba.ru/tests/${test?.id}`
      }
      return `https://school.dev.ucheba.space/tests/${test?.id}`
    }

    return ''
  }, [test])

  const getlinkPreview = useCallback((educationTask: any) => {
    const task = educationTask?.taskSelector?.task as EmployeeTaskDTO

    if (window.location.origin.includes('dev.ucheba.space')) {
      return `https://school.dev.ucheba.space/training/task/${task.id}`
    }
    if (window.location.origin.includes('dev2.ucheba.space')) {
      return `https://school.dev2.ucheba.space/training/task/${task.id}`
    }
    if (window.location.origin.includes('ucheba.ru')) {
      return `https://student.ucheba.ru/training/task/${task.id}`
    }
    return `https://student.ucheba.ru/training/task/${task.id}`
  }, [])

  const onSaveRedirect = (param: string, id?: number) => {
    if (id) {
      navigate(`/tests/${id}/tasks?${param}`)
    }
  }

  // Закрытия попапа генерации ссылки для ученика
  const onCloseGenerateLinkDialog = useCallback(() => {
    setOpenGenerateLinkDialog(false)
    refreshTest()
  }, [])

  const isAllowedAddInMyBank = useMemo(() => {
    return profile?.cabinet?.id !== 1 && profile?.id !== test?.createdBy
  }, [profile, test])

  const disableButton =
    tasksTotal === 0 || (test?.status !== 'published' && test?.createdBy !== profile?.id)

  if (!test) return null

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <ContentContainer
        elevation={0}
        sx={{
          p: { xs: 2, sm: 3 },
          display: 'flex',
          alignItems: 'flex-start',
          gap: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'unset', sm: 'space-between' },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            fontWeight={500}
            sx={{ mb: '12px', fontSize: { xs: '24px', sm: '32px' } }}
          >
            {test.name}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexWrap: 'wrap' }}>
            {test.subject && (
              <Chip label={test.subject.name} size='small' sx={chipStyle} />
            )}

            {test.class && (
              <Chip label={getClassLabel(test.class)} size='small' sx={chipStyle} />
            )}

            {test.examType && (
              <Chip size='small' label={test.examType.name} sx={chipStyle} />
            )}

            {test.type && (
              <Chip
                size='small'
                label={
                  test.type === EducationTestTypeEnum.TEST ? 'Контрольная' : 'Домашка'
                }
                sx={chipStyle}
              />
            )}

            {test.isDemo && <Chip size='small' label='Демо' sx={chipStyle} />}
          </Box>
        </Box>
      </ContentContainer>

      <Box
        sx={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {tasks.length > 0 && (
          <ContentContainer
            sx={{
              overflow: 'auto',
              p: 0,
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              mb: 2,
              gap: 2,
            }}
            elevation={0}
          >
            {tasks.map((task) => {
              const linkPreview = getlinkPreview(task)

              if (
                task.isVisible &&
                task.taskSelector?.type === 'by_id' &&
                'task' in task.taskSelector &&
                task.taskSelector.task
              ) {
                return (
                  <TaskItem
                    taskStat={tasksStats[Number(task.taskSelector.task.id)]}
                    key={task.id}
                    item={task.taskSelector.task}
                    isTasksLoading={isTasksLoading}
                    isVisibleStatus={false}
                    isVisibleSubject={false}
                    actions={[
                      <Tooltip title='Предпросмотр теста'>
                        <IconButton
                          href={linkPreview}
                          sx={{ padding: '10px' }}
                          target='_blank'
                        >
                          <RemoveRedEyeOutlinedIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Tooltip>,
                    ]}
                  />
                )
              }
            })}
          </ContentContainer>
        )}

        <ContentContainer
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', sm: 'auto' },
            p: { xs: '24px 16px', sm: 3 },
            justifyContent: { xs: 'unset', sm: 'end' },
            flexDirection: { xs: 'column', sm: 'unset' },
            gap: { xs: 2, sm: 0 },
          }}
          elevation={0}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: { xs: '100%', sm: 'auto' },
              justifyContent: { xs: 'unset', sm: 'space-between' },
              flexDirection: { xs: 'column', sm: 'unset' },
              gap: { xs: 2, sm: 1 },
            }}
          >
            {isAllowedAddInMyBank && (
              <Button
                fullWidth={isMobileScreen}
                onClick={() => setOpenGenerateLinkDialog(true)}
                variant='text'
                sx={{ p: '12px 11px' }}
              >
                публичная ссылка
              </Button>
            )}

            {isAllowedAddInMyBank && (
              <Button
                fullWidth={isMobileScreen}
                onClick={onClickCopyTestToMy}
                variant='text'
                sx={{ p: '12px 11px' }}
              >
                в мой банк
              </Button>
            )}

            <Button
              fullWidth={isMobileScreen}
              variant='contained'
              sx={{ p: '12px 22px' }}
              disabled={disableButton}
              onClick={() => {
                setIsOpenTestAssign(true)
              }}
            >
              Выдать ученику
            </Button>
          </Box>
        </ContentContainer>
      </Box>

      {isOpenTestAssign && test && (
        <TestAssignModal
          isOpen={isOpenTestAssign}
          testId={test?.id || 0}
          title={test?.name || ''}
          onClose={() => setIsOpenTestAssign(false)}
          onSave={(id?: number) => onSaveRedirect('test-assigned=true', id)}
          isCopyTest
          subjectId={String(test?.subject?.id)}
        />
      )}

      {openGenerateLinkDialog && test && (
        <TestGenerateLinkDialog
          open={openGenerateLinkDialog}
          onClose={onCloseGenerateLinkDialog}
          test={test}
          isMobileScreen={isMobileScreen}
          onSave={(id?: number) => onSaveRedirect('link-generate=true', id)}
          isCopyTest
        />
      )}
    </Stack>
  )
}

export default memo(TestTasksView)
