import React, { Fragment, ReactElement, ReactNode, useMemo } from 'react'
import ContentContainer from '../custom/ContentContainer'
import { Box, Chip, Divider, SxProps, useMediaQuery } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import theme from '../../styles/globalTheme'

interface InfoBlockHeaderProps {
  chips?: (ReactElement<typeof Chip> | null)[]
  startIcon?: ReactNode
  actions?: (ReactNode | null)[] | null
}

export function InfoBlockHeader(props: InfoBlockHeaderProps) {
  const { chips, startIcon, actions } = props
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: { xs: 2, sm: '8px 24px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          flexWrap: 'wrap',
          flexGrow: 1,
        }}
      >
        {startIcon}

        {chips?.map((chip, index) => <Fragment key={index}>{chip}</Fragment>)}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {actions?.map((action, index) => <Fragment key={index}>{action}</Fragment>)}
      </Box>
    </Box>
  )
}

interface InfoBlockContentProps {
  children?: ReactNode
  isShowOverlay?: boolean
  onClickToggleOverlay?: () => void
  onClick?: () => void
}

export function InfoBlockContent(props: InfoBlockContentProps) {
  const { children, isShowOverlay = true, onClickToggleOverlay, onClick } = props
  const hoverBlockWithCondition = useMemo(() => {
    if (!isShowOverlay) {
      return {
        '&:hover .hover-content': {
          opacity: 0.3,
        },
        '&:hover .hover-icon': {
          opacity: 1,
        },
        cursor: 'pointer',
      }
    }
    return {}
  }, [isShowOverlay])

  return (
    <Box
      onClick={() => {
        if (onClickToggleOverlay) {
          onClickToggleOverlay()
        }
        if (onClick) {
          onClick()
        }
      }}
      sx={{
        position: 'relative',
        padding: { xs: 2, sm: '20px 24px' },
        userSelect: isShowOverlay ? 'unset' : 'none',
        zIndex: 10,
        ...(!isShowOverlay && {
          '&:hover .hover-content': {
            opacity: 0.1,
          },
          '&:hover .hover-icon': {
            opacity: 1,
          },
          cursor: 'pointer',
        }),
        ...hoverBlockWithCondition,
      }}
    >
      <Box className='hover-content'>{children}</Box>

      <IconButton
        size='small'
        className='hover-icon'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0,
          padding: '4px',
          zIndex: 1,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <UnfoldMoreIcon sx={{ width: 24, height: 24 }} />
      </IconButton>
    </Box>
  )
}

interface InfoBlockFooterProps {
  footerStart?: ReactNode | null
  footerEnd?: ReactNode | null
}

export function InfoBlockFooter(props: InfoBlockFooterProps) {
  const { footerStart, footerEnd } = props

  return (
    <Box>
      <Divider />

      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'unset', sm: 'center' },
          justifyContent: !footerStart ? 'flex-end' : 'space-between',
          flexDirection: { xs: 'column', sm: 'row' },
          p: { xs: 2, sm: '24px' },
        }}
      >
        {footerStart}

        {footerEnd}
      </Box>
    </Box>
  )
}

interface InfoBlockProps {
  children: ReactNode
  sx?: SxProps
}

export default function InfoBlock({ children, sx }: InfoBlockProps) {
  return (
    <ContentContainer sx={{ p: 0, borderRadius: { sm: 3, xs: 0 }, ...sx }} elevation={0}>
      {children}
    </ContentContainer>
  )
}
