import { Editor } from '@tinymce/tinymce-react'
import * as React from 'react'
import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import getToken from '../../helpers/getToken'
import { Editor as TinyMCEEditor, TinyMCE } from 'tinymce'
import FileUploaderDialog from '../custom/FileUploaderDialog'
import { FileInfoDTO } from '../../generated/api'
import './tiny-mce.css'
import MathEditorDialog, {
  MathFormulaResult,
} from '../custom/MathEditor/MathEditorDialog'

interface ITinyMceEditorWrapperProps {
  height: string | number
  initialValue?: string | null
  onChange: (value: string) => void
  onInit?: (e: any, editor: TinyMCEEditor) => void
  minimal?: boolean
  autoSaveInterval?: number | null
  value?: string
  quickbarsInsertToolbar?: string
  quickbarsSelectionToolbar?: string
  onPaste?: (e: any) => void
  disabled?: boolean
}

const TinyMceEditorWrapper: FC<ITinyMceEditorWrapperProps> = ({
  initialValue,
  height,
  onChange,
  onInit,
  minimal = false,
  autoSaveInterval = null,
  value,
  quickbarsInsertToolbar,
  quickbarsSelectionToolbar,
  onPaste,
  disabled,
}) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = React.useState(false)
  const [editor, setEditor] = React.useState<TinyMCEEditor>()
  const [onUpload, setOnUpload] = React.useState<(file: FileInfoDTO) => void>(
    () => () => {}
  )
  const onUploadDialogClose = useCallback(() => {
    setIsUploadDialogOpen(false)
  }, [setIsUploadDialogOpen])
  const onUploadDialogInsert = useCallback(
    (file: FileInfoDTO) => {
      setIsUploadDialogOpen(false)
      onUpload(file)
    },
    [setIsUploadDialogOpen, onUpload]
  )

  const [isMathEditorDialogOpen, setIsMathEditorDialogOpen] = React.useState(false)
  const [editedFormula, setEditedFormula] = React.useState<any>()
  const onMathEditorDialogClose = useCallback(() => {
    setIsMathEditorDialogOpen(false)
    setEditedFormula(undefined)
  }, [setIsMathEditorDialogOpen])
  const onFormulaAdd = useCallback(
    (formula: MathFormulaResult) => {
      if (!editor) {
        return
      }
      if (editedFormula) {
        editedFormula.innerHTML = formula.markup
        editedFormula.dataset.latex = formula.latex
        editor.save()
      } else {
        const result = `<span class="mceNonEditable math-tex" data-latex='${formula.latex}'>${formula.markup}</span>`
        editor.insertContent(result, { merge: false })
      }
    },
    [editor, editedFormula]
  )

  const onMathEditorDialogInsert = useCallback(
    (formula: MathFormulaResult) => {
      setIsMathEditorDialogOpen(false)
      onFormulaAdd(formula)
      setEditedFormula(undefined)
    },
    [setIsMathEditorDialogOpen, onFormulaAdd]
  )

  const onEditorInit = useCallback(
    (e: any, editor: TinyMCEEditor) => {
      setEditor(editor)
      // Это необходимо для работы формул, без этого tiny удаляет пустые span даже с аттрибутам
      editor.parser.schema.elements['span'].removeEmpty = false
      if (onInit) {
        onInit(e, editor)
      }
    },
    [onInit, setEditor]
  )

  const editorInstance = useRef<TinyMCEEditor>()

  const firstInitialValue = useMemo(() => initialValue || '', [])
  const uploadHandler = useCallback((blobInfo: any, progress: any) => {
    let xhr: XMLHttpRequest, formData: FormData
    return new Promise<string>((resolve, reject) => {
      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', process.env.REACT_APP_API_URL + '/tiny/upload/image')
      xhr.setRequestHeader('Authorization', 'Bearer ' + getToken())
      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }

      xhr.onload = function () {
        var json

        if (xhr.status === 403) {
          reject('HTTP Error: ' + xhr.status)
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject('HTTP Error: ' + xhr.status)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location != 'string') {
          reject('Invalid JSON: ' + xhr.responseText)
          return
        }

        resolve(json.location)
      }

      xhr.onerror = function () {
        reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
      }

      formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())
      xhr.send(formData)
    })
  }, [])
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const options = {
    height: height,
    menubar: false,
    statusbar: !minimal,
    content_css: ['/mathlive/mathlive-static.css'],
    setup: function (editor: any) {
      editorInstance.current = editor
      editor.ui.registry.addButton('customUploadButton', {
        icon: 'embed',
        onAction: (_: any) => {
          setIsUploadDialogOpen(true)
          setOnUpload(() => (file: FileInfoDTO) => {
            if (file.mimeType?.startsWith('audio/')) {
              editor.insertContent(`<audio controls>
                    <source src="${file.url}" type="${file.mimeType}">
                    Ваш браузер не поддерживает воспроизведение аудио
                  </audio>`)
            } else {
              editor.insertContent(
                `<a href="${file.url}" target="_blank">${file.fileName}<a>`
              )
            }
          })
        },
      })
      editor.ui.registry.addIcon(
        'math',
        '<svg viewBox="0 0 576 512" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M576 32.01c0-17.69-14.33-31.1-32-31.1l-224-.0049c-14.69 0-27.48 10-31.05 24.25L197.9 388.3L124.6 241.7C119.2 230.9 108.1 224 96 224L32 224c-17.67 0-32 14.31-32 31.1s14.33 32 32 32h44.22l103.2 206.3c5.469 10.91 16.6 17.68 28.61 17.68c1.172 0 2.323-.0576 3.495-.1826c13.31-1.469 24.31-11.06 27.56-24.06l105.9-423.8H544C561.7 64.01 576 49.7 576 32.01zM566.6 233.4c-12.5-12.5-32.75-12.5-45.25 0L480 274.8l-41.38-41.37c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25l41.38 41.38l-41.38 41.38c-12.5 12.5-12.5 32.75 0 45.25C399.6 412.9 407.8 416 416 416s16.38-3.125 22.62-9.375L480 365.3l41.38 41.38C527.6 412.9 535.8 416 544 416s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25l-41.38-41.38L566.6 278.6C579.1 266.1 579.1 245.9 566.6 233.4z"/>'
      )
      editor.ui.registry.addButton('customMathEditorButton', {
        icon: 'math',
        onAction: (_: any) => {
          setIsMathEditorDialogOpen(true)
        },
      })
    },
    // skin: prefersDarkMode ? 'oxide-dark' : 'oxide',
    // content_css: prefersDarkMode ? 'dark' : 'default',
    image_uploadtab: true,
    // images_upload_base_path: '/some/basepath',
    file_picker_types: 'image',
    images_upload_handler: uploadHandler,
    draggable_modal: true,
    extended_valid_elements: '*[*]',
    formats: {
      math: {
        selector: 'span.math-tex',
        exact: true,
        remove: 'none',
        merge_siblings: false,
      },
    },
    automatic_uploads: false,
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'fullscreen',
      'media',
      'table',
      'preview',
      'help',
      'wordcount',
      'codesample',
      'code',
    ],
  }
  if (minimal) {
    options['plugins'].push('quickbars')
    Object.assign(options, {
      toolbar: false,
      toolbar_sticky: true,
      quickbars_insert_toolbar:
        typeof quickbarsInsertToolbar === 'string'
          ? quickbarsInsertToolbar
          : 'customMathEditorButton quicktable codesample customUploadButton',
      quickbars_selection_toolbar:
        typeof quickbarsSelectionToolbar === 'string'
          ? quickbarsSelectionToolbar
          : 'bold italic underline strikethrough | superscript subscript | blocks | blockquote quicklink',
      contextmenu:
        'undo redo | customMathEditorButton codesample | inserttable | cell row column deletetable | customUploadButton | help',
    })
  } else {
    Object.assign(options, {
      toolbar:
        'undo redo | blocks | ' +
        'bold italic underline strikethrough | superscript subscript | forecolor backcolor removeformat | customMathEditorButton codesample | table | image customUploadButton | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'help | code | fullscreen',
    })
  }
  const changeHandler = useCallback(
    (content: string) => {
      onChange(content)
    },
    [onChange]
  )

  useEffect(() => {
    if (!autoSaveInterval || !editorInstance) {
      return
    }
    const interval = setInterval(() => {
      editorInstance?.current?.save()
    }, autoSaveInterval)

    return () => clearInterval(interval)
  }, [autoSaveInterval, editorInstance])

  const onDblClick = useCallback(
    (e: any) => {
      const formula = e.target.closest('span.math-tex')
      if (formula) {
        setIsMathEditorDialogOpen(true)
        setEditedFormula(formula)
      }
    },
    [setEditedFormula]
  )

  useEffect(() => {
    const eventHandle = (event: Event) => {
      const block = document.getElementById('tinymceWrapperId')

      if (block && event.target && !block.contains(event.target as Node)) {
        const btn = document.getElementsByClassName(
          'tox-tbtn'
        ) as HTMLCollectionOf<HTMLButtonElement>

        Array.from(btn).forEach((b): void => {
          if (b.ariaExpanded === 'true') {
            b.click()
          }
        })
      }
    }

    if (!minimal) {
      document.addEventListener('click', eventHandle)
    }

    return () => {
      if (eventHandle) {
        document.removeEventListener('click', eventHandle)
      }
    }
  }, [])

  return (
    <div id={'tinymceWrapperId'}>
      <Editor
        init={options as any}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={firstInitialValue}
        onEditorChange={changeHandler}
        onInit={onEditorInit}
        onDblclick={onDblClick}
        value={value}
        onPaste={onPaste}
        disabled={disabled}
      />

      {isUploadDialogOpen && (
        <FileUploaderDialog
          open={isUploadDialogOpen}
          onClose={onUploadDialogClose}
          onInsert={onUploadDialogInsert}
        />
      )}

      {isMathEditorDialogOpen && (
        <MathEditorDialog
          open={true}
          onClose={onMathEditorDialogClose}
          onInsert={onMathEditorDialogInsert}
          initialValue={editedFormula ? editedFormula.dataset.latex : ''}
        />
      )}
    </div>
  )
}

export default React.memo(TinyMceEditorWrapper)
