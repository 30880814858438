import {ApiError} from "../generated/api";
import {FormikContextType} from "formik";

export default function formErrorHandle(err: ApiError, formik: FormikContextType<any>): boolean
{
  if (err.status === 400 && err.body?.errors) {
    Object.entries(err.body?.errors).forEach(([field, errors]: any) => {
      formik.setFieldError(field, errors.join(','))
    })
    return true;
  }
  return false;
}