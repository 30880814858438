import React from 'react'
import { ProgramStudentDTO } from '../../../../generated/api'
import { Box, Tooltip } from '@mui/material'
import ProgramChip from '../../ProgramChip'

interface IStudentProgramsProps {
  program: ProgramStudentDTO
}

export default function StudentPrograms(props: IStudentProgramsProps) {
  const { program } = props

  const uppercaseChar = {
    subjectName: program.program?.subject?.name || '',
    type: program?.program?.examType?.name || '',
    streamsEducation: program?.program?.streamsEducation?.map((el: any) => el.name),
    programName: program.program?.name?.trim() || '',
  }

  const tooltipTitle = `${uppercaseChar.subjectName} ${uppercaseChar.type} ${uppercaseChar.streamsEducation} ${uppercaseChar.programName}`

  return (
    <Box>
      <Tooltip title={tooltipTitle} placement='top'>
        <Box sx={{ pointerEvents: 'auto', display: 'inline' }}>
          <ProgramChip
            values={uppercaseChar}
            height='18px'
            fontSize='12px'
            lineHeight='12px'
          />
        </Box>
      </Tooltip>{' '}
      {program.program?.subject?.name && (
        <>
          <Box
            sx={{
              fontSize: '16px',
              whiteSpace: 'wrap',
              lineHeight: '24px',
              fontWeight: 400,
              display: 'inline',
              textDecoration: !program.isActive ? 'line-through' : 'inherit',
            }}
          >
            {program.program.subject.name}
          </Box>{' '}
        </>
      )}
      {program?.program?.examType?.name && (
        <>
          <Box
            sx={{
              fontSize: '16px',
              whiteSpace: 'wrap',
              lineHeight: '24px',
              fontWeight: 400,
              display: 'inline',
              textDecoration: !program.isActive ? 'line-through' : 'inherit',
            }}
          >
            {program.program.examType.name}
          </Box>{' '}
        </>
      )}
      {program?.program?.streamsEducation &&
        program?.program?.streamsEducation.length > 0 && (
          <>
            {program?.program?.streamsEducation?.map(
              (streamEducation, streamEducationIndex) => (
                <>
                  <Box
                    key={streamEducationIndex}
                    sx={{
                      fontSize: '16px',
                      whiteSpace: 'wrap',
                      lineHeight: '24px',
                      fontWeight: 400,
                      display: 'inline',
                      textDecoration: !program.isActive ? 'line-through' : 'inherit',
                    }}
                  >
                    {streamEducation.name}
                  </Box>{' '}
                </>
              )
            )}{' '}
          </>
        )}
      {program.program?.name && (
        <>
          <Box
            sx={{
              fontSize: '16px',
              whiteSpace: 'wrap',
              lineHeight: '24px',
              fontWeight: 400,
              display: 'inline',
              textDecoration: !program.isActive ? 'line-through' : 'inherit',
            }}
          >
            {program.program?.name?.trim()}
          </Box>{' '}
        </>
      )}
      {!program.isActive && (
        <Box
          sx={{
            color: '#FF0829',
            fontSize: '16px',
            whiteSpace: 'wrap',
            lineHeight: '24px',
            fontWeight: 400,
            display: 'inline',
          }}
        >
          неактивна
        </Box>
      )}
    </Box>
  )
}
