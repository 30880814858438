import { TextFieldProps, TextFieldVariants } from '@mui/material/TextField/TextField'
import TextField from '@mui/material/TextField'
import { Autocomplete, Box, Chip } from '@mui/material'
import React, { ReactNode, SyntheticEvent } from 'react'
import { ClassTypeEnum } from '../../generated/api'

interface ClassAutocompleteProps
  extends Omit<TextFieldProps, 'onChange' | 'value' | 'error'> {
  value?: ClassTypeEnum | ClassTypeEnum[] | null
  multiple?: boolean
  onChange: (event: SyntheticEvent, value: any) => void
  label?: string
  variant?: TextFieldVariants
  disableClearable?: boolean
  error?: ReactNode
}

const getOptionLabel = (option: ClassTypeEnum) => {
  switch (option) {
    case ClassTypeEnum.PRESCHOOL:
      return 'Дошкольник'
    case ClassTypeEnum.FINISHED_SCHOOL:
      return 'Закончил'
    default:
      return `${option} класс`
  }
}

export default function ClassAutocomplete({
  value,
  onChange,
  multiple = false,
  label = 'Класс',
  variant,
  size = 'small',
  disableClearable = false,
  error,
  ...props
}: ClassAutocompleteProps) {
  const option = Object.values(ClassTypeEnum).filter((option) =>
    multiple ? !(value as ClassTypeEnum[])?.includes(option) : value !== option
  )

  return (
    <Autocomplete
      disableClearable={disableClearable}
      limitTags={3}
      size={size}
      multiple={multiple}
      options={option}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={(event, newValue) => onChange(event, newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          variant={variant}
          error={!!error}
          helperText={error}
          {...props}
        />
      )}
      renderTags={(value, tagProps) =>
        value.map((option, index) => (
          <Chip
            {...tagProps({ index })}
            label={getOptionLabel(option)}
            size='small'
            sx={{
              height: 20,
            }}
          />
        ))
      }
    />
  )
}
