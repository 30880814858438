import TextField from '@mui/material/TextField'
import ApiAutocomplete from '../extended/ApiAutocomplete'
import * as React from 'react'
import { SyntheticEvent, useCallback, useState } from 'react'
import { ProgramService, type StreamEducationDTO } from '../../generated/api'
import { Box, CircularProgress, MenuItem } from '@mui/material'
import { TextFieldProps, TextFieldVariants } from '@mui/material/TextField/TextField'
import ProgramChip from '../custom/ProgramChip'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import { getProgramLevel } from '../../helpers/getProgramLevel'

interface IUpperCaseCharItem {
  subjectName: string
  type: string
  streamsEducation?: string[]
  level?: string
  programName?: string
}

interface ProgramsAutocompleteProps
  extends Omit<TextFieldProps, 'onChange' | 'value' | 'error'> {
  value: any
  multiple: boolean
  onChange: (event: SyntheticEvent, value: any) => void
  error?: React.ReactNode
  subjectId?: number
  label?: string
  variant?: TextFieldVariants
  excludeIds?: number[]
  marginLeft?: string,
  examTypeId?: number
}

export default function ProgramsAutocomplete({
  value,
  excludeIds,
  multiple,
  onChange,
  error,
  subjectId,
  label = 'Программа',
  marginLeft = '0',
  disabled,
  examTypeId,
  ...props
}: ProgramsAutocompleteProps) {
  const [isLoading, setIsLoading] = useState(false)
  const loadPrograms = useCallback(
    ({ query, ids }: { query?: string; ids?: number[] }) => {
      setIsLoading(true)
      return ProgramService.getAppApiCalendarProgramcrudGetlistforselect({
        idsArray: ids,
        query: query,
        excludeIdsArray: excludeIds,
        excludeDefault: !ids?.length,
        subjectIdsArray: subjectId ? [subjectId] : undefined,
        onlyActual: ids?.length ? undefined : true,
        examTypeIdsArray: examTypeId ? [examTypeId] : undefined
      })
        .then((response) => response || [])
        .finally(() => setIsLoading(false))
    },
    [excludeIds, subjectId, examTypeId]
  )

  return (
    <ApiAutocomplete
      groupBy={(option) => option.subject?.name || ''}
      multiple={multiple}
      load={loadPrograms}
      options={[]}
      disabled={disabled}
      getOptionLabel={(program: any) => {
        return `${program.examType?.name || ''} ${
          program.isMainStream
            ? program?.streamsEducation
                ?.map((stream: StreamEducationDTO) => stream.name)
                .join(', ')
            : program.name
        } ${getProgramLevel(program?.level || null)}`
      }}
      labelField={'name'}
      valueField={null}
      renderInput={(params) => {
        let valuesChip = {} as IUpperCaseCharItem

        if (value) {
          valuesChip = {
            subjectName: value.subject.name,
            type: value.examType ? value.examType.name : '',
          }

          if (value.isMainStream) {
            valuesChip = {
              ...valuesChip,
              streamsEducation: value.streamsEducation.map((el: any) => el.name),
              level: getProgramLevel(value.level),
            }
          } else {
            valuesChip = {
              ...valuesChip,
              programName: value.name.trim(),
              level: getProgramLevel(value.level),
            }
          }
        }
        return (
          <TextField
            {...params}
            fullWidth
            label={label}
            error={!!error}
            helperText={error}
            InputLabelProps={{
              shrink: !!value || !!params.inputProps.value,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {value ? (
                    <ProgramChip values={valuesChip} marginLeft={marginLeft} />
                  ) : null}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            {...props}
          />
        )
      }}
      renderOption={(props, option) => {
        let valuesChip = {} as IUpperCaseCharItem

        if (option) {
          valuesChip = {
            subjectName: option?.subject?.name || '',
            type: option?.examType?.name || '',
          }

          if (option.isMainStream) {
            valuesChip = {
              ...valuesChip,
              streamsEducation: option?.streamsEducation?.map((el: any) => el.name) || [],
              level: getProgramLevel(option?.level || null),
            }
          } else {
            valuesChip = {
              ...valuesChip,
              programName: option?.name?.trim() || '',
              level: getProgramLevel(option?.level || null),
            }
          }
        }

        const value = `${option.examType?.name || ''} ${
          option.isMainStream
            ? option?.streamsEducation
                ?.map((stream: StreamEducationDTO) => stream.name)
                .join(', ')
            : option.name
        } ${getProgramLevel(option?.level || null)}`

        return (
          // @ts-ignore
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'left',
              overflow: 'unset !important',
            }}
            component='div'
            {...props}
          >
            <Box sx={{ mr: '8px' }}>
              <ProgramChip values={valuesChip} />
            </Box>
            <Box>{value}</Box>
          </Box>
        )
      }}
      PaperComponent={({ children }) => (
          <Paper
              sx={{ p: 0, minWidth: isLoading ? 'unset' : { xs: '100%', sm: '500px' } }}
              elevation={1}
          >
          <MenuList sx={{ m: 0, p: 0, width: '100%' }}>
            {isLoading ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'block',
                  padding: '6px 0',
                  textAlign: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              React.Children.map(children, (child, index) => {
                return child ? (
                  <MenuItem
                    key={index}
                    sx={{ m: 0, p: 0, width: '100%', display: 'block' }}
                  >
                    {child}
                  </MenuItem>
                ) : null
              })
            )}
          </MenuList>
        </Paper>
      )}
      onChange={onChange}
      formValue={value}
    />
  )
}
