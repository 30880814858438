import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import TinyMceEditorWrapper from '../../wrappers/TinyMceEditorWrapper'
import { LoadingButton } from '@mui/lab'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ApiError,
  ManualEmployeeTaskDTO,
  TaskCheckingResultStatusDTO,
  TaskCheckingService,
  TestResultTaskDTO,
} from '../../../generated/api'
import CheckIcon from '@mui/icons-material/Check'
import defaultErrorHandle from '../../../helpers/DefaultErrorHandle'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import TaskUserTextResponse from '../TaskUserResponses/TaskUserTextResponse'
import TaskUserSelectionResponse from '../TaskUserResponses/TaskUserSelectionResponse'
import TaskUserManualTextResponse from '../TaskUserResponses/TaskUserManualTextResponse'
import TaskUserManualPhotoResponse from '../TaskUserResponses/TaskUserManualPhotoResponse'
import TaskUserManualAudioResponse from '../TaskUserResponses/TaskUserManualAudioResponse'
import ScoreRatingButton from '../ScoreRatingButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import 'mathlive/static.css'

interface TaskCheckingProps {
  taskResult: TaskCheckingResultStatusDTO
  task?: TestResultTaskDTO
  loadTaskResult?: (task: TestResultTaskDTO) => void
  onCheckComplete?: () => void
  onSetComment?: (text: string) => void
  onSetScore?: (score: number | null | undefined) => void
  isLoading?: boolean
  disabled?: boolean
}

const Accordion = styled(MuiAccordion)({
  ['&.MuiAccordion-root']: {
    margin: 0,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(19, 25, 32, 0.08)',
    ['::before']: {
      height: 0,
    },
  },
})

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: '0 24px 24px 24px',
})

const AccordionSummary = styled(MuiAccordionSummary)({})

export default function TaskChecking(props: TaskCheckingProps) {
  const {
    taskResult,
    onCheckComplete,
    loadTaskResult,
    task: taskItem,
    onSetComment,
    onSetScore,
    isLoading,
    disabled,
  } = props
  const task = taskResult.task
  const [errorText, setErrorText] = useState()
  const [comment, setComment] = useState<string>(taskResult.comment || '')
  const [score, setScore] = useState<number | null | undefined>(taskResult.score)
  const [isTaskCheckLoading, setIsTaskCheckLoading] = React.useState<boolean>(false)
  const [isSaved, setIsSaved] = useState(false)

  const onScoreChange = useCallback(
    (value: number) => {
      setScore(value)
    },
    [setScore]
  )

  const onCommentChange = useCallback((comment: string) => {
    setComment(comment)
  }, [])

  const save = useCallback(() => {
    setIsTaskCheckLoading(true)
    TaskCheckingService.postAppApiEducationtaskTaskcheckingCheck({
      id: String(taskResult.id),
      requestBody: {
        score: score || 0,
        comment: comment,
      },
    })
      .then(() => {
        setIsSaved(true)
        taskResult.comment = comment
        taskResult.score = score
        if (onCheckComplete) onCheckComplete()
      })
      .catch((err: ApiError) => {
        setErrorText(err.body.message)
        defaultErrorHandle(err)
      })
      .finally(() => {
        setIsTaskCheckLoading(false)
      })
  }, [score, comment, task, taskResult, onCheckComplete])

  useEffect(() => {
    setIsSaved(false)
  }, [comment, score, taskResult?.id])

  useEffect(() => {
    if (onSetComment) {
      onSetComment(comment)
    }
  }, [comment])

  useEffect(() => {
    if (onSetScore) {
      onSetScore(score)
    }
  }, [score])

  useEffect(() => {
    if (taskResult) {
      setComment(taskResult?.comment || '')

      setScore(typeof taskResult?.score === 'number' ? taskResult.score : null)
    }
  }, [taskResult.id])

  const disabledBtn = useMemo(() => {
    if (typeof score !== 'number') {
      return true
    } else if (isSaved) {
      return true
    }

    return taskResult.score === score && comment === (taskResult.comment || '')
  }, [score, isSaved, comment, taskResult])

  const manualTask = taskResult.task as ManualEmployeeTaskDTO

  const [conditionsExpanded, setConditionsExpanded] = useState(false)
  const [groupConditionsExpanded, setGroupConditionsExpanded] = useState(false)

  return (
    <>
      {taskResult.task?.group?.content && (
        <>
          <Accordion expanded={groupConditionsExpanded}>
            <AccordionSummary
              onClick={() => {
                setGroupConditionsExpanded((prevState) => !prevState)
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{
                padding: { xs: '16px', sm: '16px 24px' },
                height: '72px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ minWidth: '136px' }}>
                <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
                  Общее условие задачи
                </Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              sx={{ padding: { xs: '0 16px 16px 16px', sm: '0 24px 24px' } }}
            >
              <Typography variant='body1'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: taskResult.task?.group?.content || '',
                  }}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      <Accordion expanded={conditionsExpanded}>
        <AccordionSummary
          onClick={() => {
            setConditionsExpanded((prevState) => !prevState)
          }}
          expandIcon={<ArrowDropDownIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{
            padding: { sm: '16px 24px', xs: '0 16px' },
            height: { sm: '72px', xs: '100%' },
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            '& .MuiAccordionSummary-content': {
              width: '100%',
              overflow: 'hidden',
            },
          }}
        >
          <Box sx={{ display: { xs: 'inline', sm: 'flex' }, overflow: 'hidden' }}>
            <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
              Условие
            </Typography>

            {!conditionsExpanded && (
              <Typography
                sx={{
                  color: 'text.disabled',
                  lineHeight: '32px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: { xs: 'nowrap', sm: 'wrap' },
                  ml: { xs: 0, sm: 2 },
                }}
              >
                {taskResult.task?.conditionsPreview}
              </Typography>
            )}
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ padding: { xs: '0 16px 16px 16px', sm: '0 24px 24px' } }}>
          <Typography variant='body1'>
            <div
              dangerouslySetInnerHTML={{ __html: taskResult.task?.conditions || '' }}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>

      {taskResult.task?.solution && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{
              padding: { sm: '16px 24px', xs: '0 16px' },
              height: { sm: '72px', xs: '100%' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
              Решение
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              padding: { xs: '0 16px 16px 16px', sm: '0 24px 24px' },
              overflow: 'hidden',
            }}
          >
            <Typography variant='body1'>
              <Box
                sx={{ overflowX: { xs: 'auto', sm: 'unset' } }}
                dangerouslySetInnerHTML={{ __html: taskResult.task?.solution || '' }}
              ></Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}

      {taskResult.result === 'skipped' || !taskResult.lastResponse ? (
        <div
          style={{
            padding: '16px 24px',
            height: '72px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid rgba(19, 25, 32, 0.08)',
          }}
        >
          <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
            Нет ответа
          </Typography>
        </div>
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{
              padding: { sm: '16px 24px', xs: '0 16px' },
              height: { sm: '72px', xs: '100%' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
              Ответ
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{ padding: { xs: '0 16px 16px 16px', sm: '0 24px 24px' } }}
          >
            {taskResult.task?.type === 'text' && (
              <>
                <TaskUserTextResponse taskResult={taskResult} />
              </>
            )}

            {taskResult.task?.type === 'selection' && (
              <>
                <TaskUserSelectionResponse taskResult={taskResult} />
              </>
            )}

            {taskResult.task?.type === 'manual' && (
              <>
                {manualTask.withPhoto && (
                  <TaskUserManualPhotoResponse
                    taskResult={taskResult}
                    loadTaskResult={() => {
                      if (loadTaskResult && taskItem) {
                        loadTaskResult(taskItem)
                      }
                    }}
                    disabled={disabled}
                  />
                )}

                {manualTask.withAudio && (
                  <TaskUserManualAudioResponse
                    taskResult={taskResult}
                    loadTaskResult={() => {
                      if (loadTaskResult && taskItem) {
                        loadTaskResult(taskItem)
                      }
                    }}
                    disabled={disabled}
                  />
                )}

                {manualTask.withText && (
                  <TaskUserManualTextResponse
                    taskResult={taskResult}
                    loadTaskResult={() => {
                      if (loadTaskResult && taskItem) {
                        loadTaskResult(taskItem)
                      }
                    }}
                    disabled={disabled}
                  />
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{
            padding: { sm: '16px 24px', xs: '0 16px' },
            height: { sm: '72px', xs: '100%' },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
            Комментарий
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ padding: { xs: '0 16px 16px 16px', sm: '0 24px 24px' } }}>
          <TinyMceEditorWrapper
            height={200}
            initialValue={taskResult.comment}
            onChange={onCommentChange}
            value={comment}
            disabled={disabled}
          />
        </AccordionDetails>
      </Accordion>

      <Stack sx={{ padding: { xs: '16px', sm: '16px 24px' } }}>
        <Grid container justifyContent={'space-between'} sx={{ gap: { xs: 2, sm: 0 } }}>
          <Grid item xs={12} md='auto'>
            <ScoreRatingButton
              maxScore={taskResult.task?.score}
              initialScore={score}
              onScoreChange={onScoreChange}
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} md='auto'>
            <LoadingButton
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              disabled={disabledBtn || disabled}
              size={'small'}
              loading={isTaskCheckLoading}
              variant='contained'
              onClick={() => save()}
              startIcon={isSaved ? <CheckIcon color={'disabled'} /> : undefined}
            >
              {isSaved ? 'Изменения сохранены' : 'Сохранить'}
            </LoadingButton>
          </Grid>
        </Grid>

        {errorText && (
          <div
            style={{
              padding: '12px 0',
            }}
          >
            <Typography textAlign={'right'} color={'red'} fontSize={'12px'}>
              {errorText}
            </Typography>
          </div>
        )}
      </Stack>
    </>
  )
}
