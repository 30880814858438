import { Chip } from '@mui/material'
import React from 'react'

function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = (hash << 7) - hash + string.charCodeAt(i) * (i + 13)
    hash |= 0
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    let value = (hash >> (i * 8)) & 0xff
    value = Math.min(220, Math.max(50, value))
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

interface IUpperCaseCharItem {
  subjectName: string
  type: string
  streamsEducation?: string[]
  level?: string
  programName?: string
}

function getUppercaseChar(values: IUpperCaseCharItem) {
  return Object.keys(values).reduce((acc, key) => {
    const value = values[key as keyof IUpperCaseCharItem]

    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (typeof item === 'string' && item.trim()) {
          acc += item.trim()[0].toUpperCase()
        }
      })
    } else if (typeof value === 'string' && value.trim()) {
      acc += value.trim()[0].toUpperCase()
    }

    return acc
  }, '')
}

interface IProgramChipProps {
  values: IUpperCaseCharItem
  marginLeft?: string
  height?: string
  fontSize?: string
  lineHeight?: string
}

export default function ProgramChip(props: IProgramChipProps) {
  const {
    values,
    marginLeft = '0',
    height = '16px',
    fontSize = '10px',
    lineHeight = '10px',
  } = props

  return (
    <Chip
      sx={{
        backgroundColor: stringToColor(getUppercaseChar(values)),
        color: 'white',
        borderRadius: '4px',
        height: height,
        padding: '3px 0',
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontWeight: 600,
        userSelect: 'none',
        marginLeft: marginLeft,
        '& .MuiChip-label': {
          paddingLeft: '4px',
          paddingRight: '4px',
        },
      }}
      size='small'
      label={getUppercaseChar(values)}
    />
  )
}
