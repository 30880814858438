import React from 'react'
import { Dialog as DialogMui, DialogProps, useMediaQuery } from '@mui/material'
import theme from '../../../styles/globalTheme'

interface Props extends DialogProps {}

export default function Dialog(props: Props) {
  const { children, ref } = props

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const sx = props.sx

  return (
    <DialogMui
      ref={ref}
      scroll={isMobileScreen ? 'paper' : 'body'}
      fullScreen={isMobileScreen}
      {...props}
      sx={{
        '& .MuiDialogContent-root': { p: 0 },
        '& .MuiPaper-root': { borderRadius: { xs: 0, sm: '12px' } },
        ...sx,
      }}
    >
      {children}
    </DialogMui>
  )
}
