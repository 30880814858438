import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TaskCheckingResultStatusDTO,
  TaskCheckingService,
  TaskResultEnum,
} from '../../../generated/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import TaskChecking from '../../custom/TaskChecking'
import TestCheckingResultTasksListHeader from './components/TestCheckingResultTasksListHeader'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { LoadingButton } from '@mui/lab'
import IconCommentCheck from './assets/icon__comment_check.svg'
import IconCommentCheckSuccess from './assets/icon__comment_check_fullfield.svg'

export default function TaskCheckingResultTasksList() {
  const resultId = parseInt(useParams()?.resultId || '')
  const navigate = useNavigate()
  const [editedTaskResult, setEditedTaskResult] =
    React.useState<TaskCheckingResultStatusDTO | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingFinish, setLoadingFinish] = React.useState<boolean>(false)
  const [saved, setSaved] = useState(false)
  const [comment, setComment] = useState('')
  const [score, setScore] = useState<number | null | undefined>(null)
  const { hash, pathname } = useLocation()

  const handleScrollToComment = useCallback(() => {
    if (hash) {
      const el = document.getElementById(hash.replace('#', ''))

      if (el) {
        el.scrollIntoView()

        navigate(pathname)
      }
    }
  }, [hash, pathname])

  useEffect(() => {
    handleScrollToComment()
  })

  const refresh = useCallback(async () => {
    setIsLoading(true)

    let taskResultForEdit =
      await TaskCheckingService.getAppApiEducationtaskTaskcheckingGetbyid({
        id: resultId,
      })

    setIsLoading(false)
    setEditedTaskResult(taskResultForEdit)
  }, [setEditedTaskResult, resultId])

  const task = useMemo(() => {
    return editedTaskResult?.task
  }, [editedTaskResult])

  useEffect(() => {
    refresh()
  }, [resultId])

  const onCheckingComplete = useCallback(() => {
    refresh()

    setSaved(true)
  }, [refresh])

  const taskName = useMemo(() => {
    if (task && task.examStructTasks && task.examStructTasks[0]) {
      const examStructTask = task.examStructTasks[0]

      if (examStructTask) {
        return `№${examStructTask.number} ${examStructTask.examType?.name || ''}`
      }
    }

    return ''
  }, [task])

  const renderStatus = useMemo(() => {
    if (editedTaskResult?.result === TaskResultEnum.SKIPPED) {
      if (!!editedTaskResult.comment) {
        return <img src={IconCommentCheck} alt={''} />
      }

      return <CheckCircleIcon sx={{ color: 'rgba(19, 25, 32, 0.38)', width: '24px' }} />
    }

    if (!editedTaskResult?.result) {
      return <WarningIcon sx={{ color: 'rgba(255, 8, 41, 1)', width: '24px' }} />
    }

    if (!!editedTaskResult.comment) {
      return <img src={IconCommentCheckSuccess} alt={''} />
    }

    return <CheckCircleIcon sx={{ color: 'rgba(64, 187, 69, 1)', width: '24px' }} />
  }, [editedTaskResult])

  const StatusElement = useMemo(() => {
    if (typeof editedTaskResult?.score === 'number') {
      return (
        <div>
          {editedTaskResult.score}
          <span style={{ color: 'rgba(19, 25, 32, 0.38)' }}> из </span>
          {editedTaskResult.task?.score}

          <span>{editedTaskResult.result === 'skipped' ? ', пропущено' : ''}</span>
        </div>
      )
    } else {
      return <div>Не проверено</div>
    }
  }, [editedTaskResult])

  const onFinishChecking = useCallback(async () => {
    setLoadingFinish(true)

    if (editedTaskResult) {
      await TaskCheckingService.postAppApiEducationtaskTaskcheckingCheck({
        id: String(editedTaskResult.id),
        requestBody: {
          score: score || 0,
          comment: comment,
        },
      })
    }

    TaskCheckingService.getAppApiEducationtaskTaskcheckingGetlistforchecking({
      limit: 1,
      isChecked: 0 as any,
      isForBank: true,
    })
      .then((response) => {
        if (response.items && response.items[0]) {
          const item = response.items[0]

          if (item.comment) {
            setComment(item.comment)
          } else {
            setComment('')
          }

          if (typeof item.score === 'number') {
            setScore(item.score)
          } else {
            setScore(null)
          }

          navigate(`/checking/tasks/${item.id}`)
        } else {
          navigate(`/checking/tasks`)
        }
      })
      .catch(() => {
        navigate(`/checking/tasks`)
      })
      .finally(() => {
        setLoadingFinish(false)
      })
  }, [navigate, setLoadingFinish, comment, score])

  const isDone = useMemo(() => {
    if (editedTaskResult) {
      return typeof editedTaskResult.score === 'number'
    }

    return false
  }, [editedTaskResult])

  useEffect(() => {
    if (isDone && saved) {
      const block = document.querySelector('.checking_result_tasks_list')

      if (block) {
        setTimeout(() => {
          block.scrollTo({
            top: block.scrollHeight,
            behavior: 'smooth',
          })
        }, 300)
      }

      setSaved(false)
    }
  }, [isDone, saved])

  return (
    <div
      style={{ overflow: 'auto', height: '100%' }}
      className={'checking_result_tasks_list'}
    >
      {!editedTaskResult && isLoading && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}

      <Grid>
        {editedTaskResult && task && (
          <>
            <TestCheckingResultTasksListHeader
              testName={taskName}
              userName={editedTaskResult.user?.name}
              studentId={editedTaskResult.user?.id}
            />

            <Stack
              bgcolor={'white'}
              sx={{ borderRadius: { xs: 0, sm: '12px' } }}
              overflow={'hidden'}
            >
              <Box
                sx={{
                  padding: { sm: '16px 24px', xs: '0 16px' },
                  height: '72px',
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid rgba(19, 25, 32, 0.08)',
                }}
              >
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                    <Grid container alignItems={'center'}>
                      <Grid
                        item
                        marginRight={'8px'}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        {renderStatus}
                      </Grid>

                      <Grid item>
                        <Typography
                          fontSize={'20px'}
                          lineHeight={'32px'}
                          fontWeight={500}
                        >
                          {task.id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography
                      color={'rgba(19, 25, 32, 0.87)'}
                      fontSize={'20px'}
                      lineHeight={'32px'}
                      fontWeight={400}
                    >
                      {StatusElement}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <TaskChecking
                taskResult={editedTaskResult}
                task={task}
                onCheckComplete={onCheckingComplete}
                loadTaskResult={onCheckingComplete}
                onSetComment={(txt) => {
                  setComment(txt)
                }}
                onSetScore={(value) => {
                  setScore(value)
                }}
                isLoading={isLoading}
              />

              <div id={'comment'} />
            </Stack>
          </>
        )}

        {isDone && (
          <Grid padding={'16px 0 0 0'}>
            <LoadingButton
              loading={loadingFinish}
              onClick={onFinishChecking}
              variant={'outlined'}
              fullWidth
            >
              работа проверена, к следующей
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
