import {ProgramLevelEnum} from "../generated/api";

export function getProgramLevel(level: ProgramLevelEnum | null) {
    switch (level) {
        case ProgramLevelEnum.MEDIUM:
            return 'Средний'
        case ProgramLevelEnum.ADVANCED:
            return 'Продвинутый'
        case ProgramLevelEnum.HARD:
            return 'Хардкор'
        default:
            return ''
    }
}