import Box from '@mui/material/Box'
import * as React from 'react'
import { Drawer, Grid, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { Link, Outlet, useLocation } from 'react-router-dom'
import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { ReactComponent as IconUcheba } from './assets/logo-ucheba-vector.svg'
import { ReactComponent as IconUchebaFull } from './assets/logo-ucheba-vector_full.svg'
import { ReactComponent as IconUchebaFullMobile } from './assets/logo-ucheba-vector_mobile.svg'
import NavItem from '../../components/custom/NavItem'
import styles from './styles.module.css'
import Navigation from '../../components/custom/Navigation'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MoreButton from '../../components/custom/MoreButton'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { ProfileContext } from '../../App'
import { logout } from '../../helpers/logout'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import TgAlertProvider from '../../components/wrappers/TgAlertProvider'
import UserAvatar from '../../components/custom/UserAvatar'
import More from '../../components/custom/More'
import HelpIcon from '@mui/icons-material/Help'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import VideoInstructionsDialog from '../../components/custom/VideoInstructionsDialog'

const Layout: FC = () => {
  const [isFull, setIsFull] = useState(false)
  const refSidebar = useRef<HTMLElement | null>(null)
  const { pathname } = useLocation()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [videoInstructionsDialog, setVideoInstructionsDialog] =
    React.useState<boolean>(false)
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [setMobileOpen, mobileOpen])

  useEffect(() => {
    setMobileOpen(false)
  }, [pathname])

  const onHandleResize = useCallback(() => {
    if (window.innerWidth >= 1366) {
      setIsFull(true)
    } else {
      setIsFull(false)
    }
  }, [])

  useLayoutEffect(() => {
    onHandleResize()
    window.addEventListener('resize', onHandleResize)

    return () => {
      window.removeEventListener('resize', onHandleResize)
    }
  }, [])

  const profile = useContext(ProfileContext)

  return (
    <Grid container sx={{ minWidth: { xs: 'auto', sm: '1024px' } }}>
      <Grid className={styles.sidebar__root__mobile} item width={'100%'}>
        <Box
          padding={'4px'}
          bgcolor={'#263340'}
          width={'100%'}
          top={0}
          left={0}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>

          <Link to={'/'} style={{ display: 'inline-flex' }}>
            <IconUchebaFullMobile />
          </Link>

          <MoreButton
            content={() => {
              return (
                <Paper
                  style={{
                    padding: '8px 0',
                    width: '161px',
                    backgroundColor: '#263340',
                    borderRadius: '16px',
                  }}
                >
                  <Box>
                    <MenuList style={{ padding: 0 }}>
                      <MenuItem
                        style={{
                          height: '48px',
                        }}
                      >
                        <ListItemIcon color={'white'}>
                          <SettingsIcon sx={{ color: 'white' }} />
                        </ListItemIcon>

                        <ListItemText style={{ color: 'white' }}>
                          <NavItem text='Настройки' href={'/settings'} />
                        </ListItemText>
                      </MenuItem>

                      <MenuItem
                        style={{
                          height: '48px',
                        }}
                        onClick={() => {
                          logout()
                        }}
                      >
                        <ListItemIcon color={'white'}>
                          <LogoutIcon sx={{ color: 'white' }} />
                        </ListItemIcon>

                        <ListItemText sx={{ color: 'white !important' }}>
                          <NavItem text='Выход' />
                        </ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Box>
                </Paper>
              )
            }}
          >
            <Box sx={{ mr: '12px' }}>
              <UserAvatar
                name={profile?.name || ''}
                size='24px'
                fontSize='10px'
                lineHeight='12px'
              />
            </Box>
          </MoreButton>

          <Drawer
            sx={{
              height: '100vh',
              '.MuiDrawer-paper, .MuiBackdrop-root': {
                maxHeight: '100vh',
              },
            }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <Box
              ref={refSidebar}
              borderRadius={'0px'}
              bgcolor={'#263340'}
              color={'white'}
              padding={'8px'}
              height={'100vh'}
              maxWidth={'70vw'}
              overflow={'auto'}
            >
              <Grid container rowSpacing={1} height={'100%'}>
                <Grid item xs={12}>
                  <Box padding={'8px'} width={'100%'}>
                    <div
                      style={{
                        padding: '8px 0',
                      }}
                    >
                      <Link to={'/'}>
                        <IconUchebaFull />
                      </Link>
                    </div>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box padding={'8px'} width={'100%'}>
                    <Navigation isFull limit={99} />
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{ p: '8px 16px' }} gap={0.5}>
                  <Box display={'flex'} alignItems={'center'}>
                    <PlayCircleIcon sx={{ color: 'white' }} />
                    <Box style={{ color: 'white' }}>
                      <NavItem
                        text='Видеоинструкция'
                        onClick={() => setVideoInstructionsDialog(true)}
                      />
                    </Box>
                  </Box>

                  <Box display={'flex'} alignItems={'center'}>
                    <WhatsAppIcon sx={{ color: 'white' }} />
                    <Box style={{ color: 'white' }}>
                      <NavItem
                        text='Служба поддержки'
                        target={'_blank'}
                        href={
                          'https://api.whatsapp.com/send/?phone=79169949316&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%A3%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D1%82%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%3A'
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </Box>
      </Grid>

      <Grid className={styles.sidebar__root} item xs={'auto'} position={'relative'}>
        <Box padding={'24px 0 24px 24px'} height={'100%'}>
          <div className={styles.Layout__block__sidebar} />

          <Box
            className={styles.sidebar__box}
            borderRadius={'12px'}
            bgcolor={'#263340'}
            color={'white'}
            padding={'0px'}
          >
            <Grid container rowSpacing={1} height={'100%'}>
              <Grid item xs={12}>
                <Box padding={'16px'} width={'100%'}>
                  {!isFull && <NavItem IconElement={IconUcheba} href={'/'} />}

                  {isFull && (
                    <div
                      style={{
                        padding: '8px 0',
                      }}
                    >
                      <Link to={'/'}>
                        <IconUchebaFull />
                      </Link>
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} alignSelf='center'>
                <Box padding={'16px'} width={'100%'}>
                  <Navigation isFull={isFull} />
                </Box>
              </Grid>

              <Grid item xs={12} display={'flex'} alignItems={'flex-end'}>
                <Box padding={'16px 0 16px 16px'} width={'100%'}>
                  <More
                    content={
                      <Paper
                        style={{
                          padding: '8px 0',
                          width: '212px',
                          backgroundColor: '#263340',
                          borderRadius: '16px',
                        }}
                      >
                        <Box>
                          <MenuList style={{ padding: 0 }}>
                            <MenuItem
                              style={{
                                height: '48px',
                              }}
                            >
                              <ListItemIcon color={'white'}>
                                <PlayCircleIcon sx={{ color: 'white' }} />
                              </ListItemIcon>

                              <ListItemText sx={{ color: 'white !important' }}>
                                <NavItem
                                  text='Видеоинструкция'
                                  onClick={() => setVideoInstructionsDialog(true)}
                                />
                              </ListItemText>
                            </MenuItem>

                            <MenuItem
                              style={{
                                height: '48px',
                              }}
                            >
                              <ListItemIcon color={'white'}>
                                <WhatsAppIcon sx={{ color: 'white' }} />
                              </ListItemIcon>

                              <ListItemText style={{ color: 'white' }}>
                                <NavItem
                                  target={'_blank'}
                                  href={
                                    'https://api.whatsapp.com/send/?phone=79169949316&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%A3%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D1%82%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%3A'
                                  }
                                  text={'Служба поддержки'}
                                />
                              </ListItemText>
                            </MenuItem>
                          </MenuList>
                        </Box>
                      </Paper>
                    }
                  >
                    <Box
                      sx={{
                        overflow: 'hidden',
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          top: '13px',
                          right: '0',
                          width: '16px',
                          height: '20px',
                          background:
                            'linear-gradient(to left, #263340, rgba(38, 51, 64, 0))',
                          pointerEvents: 'none',
                        },
                      }}
                    >
                      <NavItem
                        Icon={<HelpIcon sx={{ color: 'white' }} />}
                        text={isFull ? 'Поддержка' : undefined}
                      />
                    </Box>
                  </More>

                  <More
                    content={
                      <Paper
                        style={{
                          padding: '8px 0',
                          width: '161px',
                          backgroundColor: '#263340',
                          borderRadius: '16px',
                        }}
                      >
                        <Box>
                          <MenuList style={{ padding: 0 }}>
                            <MenuItem
                              style={{
                                height: '48px',
                              }}
                            >
                              <ListItemIcon color={'white'}>
                                <SettingsIcon sx={{ color: 'white' }} />
                              </ListItemIcon>

                              <ListItemText style={{ color: 'white' }}>
                                <NavItem text='Настройки' href={'/settings'} />
                              </ListItemText>
                            </MenuItem>

                            <MenuItem
                              style={{
                                height: '48px',
                              }}
                              onClick={() => {
                                logout()
                              }}
                            >
                              <ListItemIcon color={'white'}>
                                <LogoutIcon sx={{ color: 'white' }} />
                              </ListItemIcon>

                              <ListItemText sx={{ color: 'white !important' }}>
                                <NavItem text='Выход' />
                              </ListItemText>
                            </MenuItem>
                          </MenuList>
                        </Box>
                      </Paper>
                    }
                  >
                    <Box
                      sx={{
                        overflow: 'hidden',
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          top: '13px',
                          right: '0',
                          width: '16px',
                          height: '20px',
                          background:
                            'linear-gradient(to left, #263340, rgba(38, 51, 64, 0))',
                          pointerEvents: 'none',
                        },
                      }}
                    >
                      <NavItem
                        withAvatar
                        avatarUrl={profile?.avatar}
                        text={isFull ? profile?.name || '' : undefined}
                      />
                    </Box>
                  </More>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid
        minHeight={'100%'}
        item
        sx={{ padding: { sm: '24px', xs: '0.1px' } }}
        xs
        zeroMinWidth
      >
        <TgAlertProvider
          onClose={() => {
            const videoInstructionsDialogId = localStorage.getItem(
              'VideoInstructionsDialogId'
            )

            if (!videoInstructionsDialogId) {
              setTimeout(() => {
                setVideoInstructionsDialog(true)

                localStorage.setItem('VideoInstructionsDialogId', 'true')
              }, 600)
            }
          }}
        >
          <Outlet />
        </TgAlertProvider>
      </Grid>

      <VideoInstructionsDialog
        open={videoInstructionsDialog}
        onClose={() => {
          setVideoInstructionsDialog(false)
        }}
        onOpen={() => {
          setVideoInstructionsDialog(true)
        }}
      />
    </Grid>
  )
}

export default memo(Layout)
