import { ParseOptions } from 'query-string'

export const queryStringBaseOptions = {
  arrayFormat: 'none' as ParseOptions['arrayFormat'],
  parseNumbers: false,
  parseBooleans: false,
  parseFragmentIdentifier: true,
  skipEmptyString: true,
  skipNull: true,
}
