/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassTypeEnum } from '../models/ClassTypeEnum';
import type { EmployeeTaskDTO } from '../models/EmployeeTaskDTO';
import type { TaskGetListResponse } from '../models/TaskGetListResponse';
import type { TaskStatusEnum } from '../models/TaskStatusEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommonTasksService {
    /**
     * Получение списка задач.
     * @returns TaskGetListResponse Список задач
     * @throws ApiError
     */
    public static getAppApiEducationtaskCommontaskGetlist({
        query,
        limit,
        offset,
        subjectIdsArray,
        topicIdsArray,
        examStructTaskIdsArray,
        isPublished,
        examTypeIdsArray,
        id,
        idsArray,
        excludeIdsArray,
        classesArray,
        statusesArray,
        taskSourceIdsArray,
        typesArray,
    }: {
        query?: string | null,
        limit?: number,
        offset?: number,
        subjectIdsArray?: Array<number>,
        topicIdsArray?: Array<number> | null,
        examStructTaskIdsArray?: Array<number> | null,
        isPublished?: boolean | null,
        examTypeIdsArray?: Array<number> | null,
        id?: number | null,
        idsArray?: Array<number> | null,
        excludeIdsArray?: Array<number>,
        classesArray?: Array<ClassTypeEnum> | null,
        statusesArray?: Array<TaskStatusEnum> | null,
        taskSourceIdsArray?: Array<number> | null,
        typesArray?: Array<string> | null,
    }): CancelablePromise<TaskGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/common-tasks',
            query: {
                'query': query,
                'limit': limit,
                'offset': offset,
                'subjectIds[]': subjectIdsArray,
                'topicIds[]': topicIdsArray,
                'examStructTaskIds[]': examStructTaskIdsArray,
                'isPublished': isPublished,
                'examTypeIds[]': examTypeIdsArray,
                'id': id,
                'ids[]': idsArray,
                'excludeIds[]': excludeIdsArray,
                'classes[]': classesArray,
                'statuses[]': statusesArray,
                'taskSourceIds[]': taskSourceIdsArray,
                'types[]': typesArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Копирование задачи
     * @returns EmployeeTaskDTO Скопированная задача
     * @throws ApiError
     */
    public static postAppApiEducationtaskCommontaskCreate({
        id,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
    }): CancelablePromise<EmployeeTaskDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/common-tasks/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
}
