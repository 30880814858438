/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassTypeEnum } from '../models/ClassTypeEnum';
import type { EducationTestDTO } from '../models/EducationTestDTO';
import type { EducationTestGetListResponse } from '../models/EducationTestGetListResponse';
import type { EducationTestStatusEnum } from '../models/EducationTestStatusEnum';
import type { EducationTestTaskGetListResponse } from '../models/EducationTestTaskGetListResponse';
import type { EducationTestTypeEnum } from '../models/EducationTestTypeEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommonTestsService {
    /**
     * Получение списка EducationTest.
     * @returns EducationTestGetListResponse Список EducationTest
     * @throws ApiError
     */
    public static getAppApiEducationCommontestsGetlist({
        limit,
        offset,
        query,
        subjectIdsArray,
        type,
        classesArray,
        isPublished,
        topicIdsArray,
        examTypeIdsArray,
        statusesArray,
        isDemo,
        isPracticeExam,
    }: {
        limit?: number,
        offset?: number,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        type?: EducationTestTypeEnum | null,
        classesArray?: Array<ClassTypeEnum>,
        isPublished?: boolean | null,
        topicIdsArray?: Array<number>,
        examTypeIdsArray?: Array<number>,
        statusesArray?: Array<EducationTestStatusEnum>,
        isDemo?: boolean | null,
        isPracticeExam?: boolean | null,
    }): CancelablePromise<EducationTestGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/common-tests',
            query: {
                'limit': limit,
                'offset': offset,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'type': type,
                'classes[]': classesArray,
                'isPublished': isPublished,
                'topicIds[]': topicIdsArray,
                'examTypeIds[]': examTypeIdsArray,
                'statuses[]': statusesArray,
                'isDemo': isDemo,
                'isPracticeExam': isPracticeExam,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение EducationTest по идентификатору.
     * @returns EducationTestDTO EducationTest
     * @throws ApiError
     */
    public static getAppApiEducationCommontestsGetbyid({
        id,
    }: {
        /**
         * Идентификатор EducationTest
         */
        id: number,
    }): CancelablePromise<EducationTestDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/common-tests/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTest с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Получение списка EducationTestTask.
     * @returns EducationTestTaskGetListResponse Список EducationTestTask
     * @throws ApiError
     */
    public static getAppApiEducationCommontestsGettasks({
        testId,
        limit,
        offset,
    }: {
        testId: string,
        limit?: number,
        offset?: number,
    }): CancelablePromise<EducationTestTaskGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/common-tests/{testId}/tasks',
            path: {
                'testId': testId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Копирование теста
     * @returns EducationTestDTO Скопированный тест
     * @throws ApiError
     */
    public static postAppApiEducationCommontestsCopy({
        id,
    }: {
        /**
         * Идентификатор теста для копирования
         */
        id: number,
    }): CancelablePromise<EducationTestDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/common-tests/{id}/copy',
            path: {
                'id': id,
            },
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
}
