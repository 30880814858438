import Paper, { PaperProps } from '@mui/material/Paper'
import React from 'react'

const ContentContainer = (props: PaperProps) => {
  const sx = props.sx

  return <Paper {...props} sx={{ p: 2, borderRadius: { sm: 3, xs: 0 }, ...sx }} />
}

export default ContentContainer
