/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RightsEnum {
    SUPERADMIN = 'superadmin',
    EXAM_STRUCT_TASK = 'exam_struct_task',
    EXAM_TYPE = 'exam_type',
    SUBJECT = 'subject',
    TOPIC = 'topic',
    TASK_SOURCES = 'task_sources',
    ADMIN = 'admin',
    EMPLOYEE = 'employee',
    PROGRAMS = 'programs',
    STUDENTS = 'students',
    LESSONS = 'lessons',
    TASKS = 'tasks',
    TASKS_BANK = 'tasks_bank',
    TESTS = 'tests',
    TESTS_BANK = 'tests_bank',
    WEBINARS = 'webinars',
    WEBINARS_GROUP_EDIT = 'webinars_group_edit',
    MY_TEST_RESULTS_VIEW = 'my_test_results_view',
    ALL_TEST_RESULT_VIEW = 'all_test_result_view',
    TEST_CHECKING = 'test_checking',
    SELECT_OTHER_TEACHERS_FOR_INDIVIDUAL_TEST = 'select_other_teachers_for_individual_test',
    PROGRAMS_PAYMENT = 'programs_payment',
}
