import TextField from '@mui/material/TextField'
import ApiAutocomplete from '../extended/ApiAutocomplete'
import * as React from 'react'
import { SyntheticEvent, useCallback, useState } from 'react'
import { EmployeeService, StreamEducationDTO, TopicService } from '../../generated/api'
import { TextFieldProps, TextFieldVariants } from '@mui/material/TextField/TextField'
import UserAvatar from '../custom/UserAvatar'
import { Box, CircularProgress, MenuItem } from '@mui/material'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'

interface EmployeeAutocompleteProps
  extends Omit<TextFieldProps, 'onChange' | 'value' | 'error'> {
  value: any
  multiple: boolean
  onChange: (event: SyntheticEvent, value: any) => void
  subjectId?: number
  roleIds: number[]
  error?: React.ReactNode
  label?: string
  variant?: TextFieldVariants
  marginLeft?: string
}

export const TEACHER_ROLE = 4
export const CURATOR_ROLE = 5

export default function EmployeeAutocomplete({
  value,
  multiple,
  onChange,
  error,
  subjectId,
  roleIds,
  label = 'Преподаватель',
  marginLeft = '0',
  disabled,
  ...props
}: EmployeeAutocompleteProps) {
  const [isLoading, setIsLoading] = useState(false)
  const loadEmployees = useCallback(
    ({ query, ids }: { query?: string; ids?: number[] }) => {
      setIsLoading(true)
      return EmployeeService.getAppApiUserEmployeecrudGetlistforselect({
        idsArray: ids,
        query: query,
        subjectId: subjectId?.toString() || null,
        roleIdsArray: roleIds,
      })
        .then((response) => response)
        .finally(() => setIsLoading(false))
    },
    [subjectId]
  )

  return (
    <ApiAutocomplete
      disabled={disabled}
      multiple={multiple}
      load={loadEmployees}
      options={[]}
      labelField='name'
      valueField='id'
      renderOption={(props, option) => {
        return option.name ? (
          //@ts-ignore
          <Box
            sx={{
              display: 'flex',
              alignContent: 'flex-start',
              alignItems: 'flex-start',
              padding: '8px 8px 8px 16px',
              overflow: 'unset !important',
            }}
            component='div'
            {...props}
          >
            <Box sx={{ mr: '12px' }}>
              <UserAvatar
                name={option.name}
                size='24px'
                fontSize='10px'
                lineHeight='12px'
              />
            </Box>
            <Box>{option.name}</Box>
          </Box>
        ) : null
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={!!error}
          helperText={error}
          InputLabelProps={{
            shrink: !!value || !!params.inputProps.value,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {typeof params.inputProps.value === 'string' &&
                value &&
                params.inputProps.value ? (
                  <UserAvatar name={params.inputProps.value} marginLeft={marginLeft} />
                ) : null}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          {...props}
        />
      )}
      PaperComponent={({ children }) => (
        <Paper sx={{ p: 0 }} elevation={1}>
          <MenuList sx={{ m: 0, p: 0, width: '100%' }}>
            {isLoading ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'block',
                  padding: '6px 0',
                  textAlign: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              React.Children.map(children, (child, index) => {
                return child ? (
                  <MenuItem
                    key={index}
                    sx={{ m: 0, p: 0, width: '100%', display: 'block' }}
                  >
                    {child}
                  </MenuItem>
                ) : null
              })
            )}
          </MenuList>
        </Paper>
      )}
      onChange={onChange}
      formValue={value}
    />
  )
}
